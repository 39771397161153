.engagementPageContent {
  flex: 1;
  display: flex;
  .sideBarAppSelect {
    width: 280px !important;
    border-left: 1px solid $border-color;
    margin-left: auto;
    .view {
      display: flex;
      flex-direction: column;
      .appPublication {
        flex: 0 0 16.66%;
        border-bottom: 1px solid $border-color;
        padding: 20px;
        display: flex;
        align-items: center;
        overflow: hidden;
        img {
          height: 40px;
          width: 40px;
          margin-right: 8px;
        }
        .nameNbtns {
          flex: 1;
          width: 0;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding-right: 30px;
          .name {
            width: 100%;
            font-weight: 600;
            font-size: 22px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .btns {
            display: flex;
            .btnAction {
              cursor: pointer;
              display: flex;
              justify-content: center;
              align-items: center;
              border: 1px solid $border-color;
              font-size: 12px;
              color: rgb(109, 109, 109);
              width: 35%;
              margin-right: 6px;
            }
          }
        }
        &:hover {
          background: rgba(239, 239, 239, 0.26);
        }
      }
    }
  }
  .copyPubModal {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    .overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.65);
      z-index: 0;
    }
    .modalCopy {
      margin: auto;
      z-index: 1;
      height: 450px;
      width: 550px;
      background: white;
      border-radius: 8px;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      .head {
        height: 75px;
        background: #464b4e;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          height: 40%;
          filter: brightness(0) invert(1);
        }
      }
      .copyContent {
        flex: 1;
        padding: 60px;
        line-height: 1;
        overflow: auto;
        .cpLabel {
          font-size: 22px;
          font-weight: 600;
        }
        .btnDrop {
          margin-top: 20px;
          height: 55px;
          display: flex;
          .btnDropdown {
            border: 1px solid $border-color;
            flex: 1;
            margin-right: 10px;
            padding: 0 25px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;
            text-transform: capitalize;
            .dropList {
              position: absolute;
              top: 100%;
              left: -1px;
              right: -1px;
              height: 320%;
              background: white;
              z-index: 2;
              border: 1px solid $border-color;
              overflow: auto;
              .dropItem {
                height: 54px;
                border-bottom: 1px solid $border-color;
                display: flex;
                align-items: center;
                padding: 0 25px;
                text-transform: capitalize;
              }
            }
          }
          .btnShow {
            border: 1px solid $border-color;
            padding: 0 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }
        }
        .res {
          margin-top: 80px;
          display: flex;
          padding: 0 10px;
          padding-bottom: 10px;
          border-bottom: 1px solid $border-color;
          .resVal {
            flex: 1;
            text-transform: capitalize;
          }
          .icon {
            font-size: 24px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
