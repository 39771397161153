.Justice,
.Ecosystems {
  .justiceContent {
    display: flex;
    height: 0;
    flex: 1;
    .justiceListWrap {
      width: 0;
      flex: 1;
      > div {
        display: flex;
        flex-direction: column;
        .listItem {
          flex: 0 0 18%;
          padding: 0 50px;
          border-bottom: 1px solid $border-color;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 20px;
          font-weight: 500;
          img {
            height: 60%;
            max-width: 350px;
          }
          .eco {
            display: flex;
            font-weight: bold;
            svg {
              width: 30px;
              height: 30px;
              margin-right: 8px;
            }
            &.asset {
              color: #464b4e;
              svg {
                path {
                  fill: #464b4e;
                }
              }
            }
            &.justice {
              color: #334480;
              svg {
                path {
                  fill: #334480;
                }
              }
            }
            &.tf {
              color: #f45d48;
              svg {
                path {
                  fill: #f45d48;
                }
              }
            }
          }
          &:hover {
            background: whitesmoke;
            * {
              zoom: 1.15;
            }
          }
        }
      }
    }
    .filterConfig {
      border-left: 1px solid $border-color;
      flex: 0 0 480px;
      display: flex;
      flex-direction: column;
      .title {
        flex: 0 0 18%;
        border-bottom: 1px solid $border-color;
        padding: 0 50px;
        display: flex;
        align-items: center;
        font-size: 24px;
        font-weight: 600;
        line-height: 1.3;
      }
      .filterScroll {
        height: 0;
        flex: 1;
        > div {
          padding: 40px 0 40px 40px;
          display: flex;
          flex-direction: column;
          .filtrationDegree {
            display: flex;
            flex-direction: column;
            margin-bottom: 20px;
            .titlefilter {
              font-size: 24px;
              font-weight: 600;
            }
            .subtitle {
              padding-right: 20px;
              display: flex;
              justify-content: space-between;
              font-size: 15px;
              color: #555555;
              margin-bottom: 10px;
              span {
                span {
                  font-weight: 600;
                }
              }
            }
            .typleListWrapper {
              height: unset !important;
              flex: 0 0 60px;
              margin: 10px 0;
              > div {
                display: flex;
                &:after {
                  content: "";
                  flex: 0 0 1px;
                  height: 100%;
                }
                .type {
                  border: 1px solid $border-color;
                  flex: 0 0 38%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin-right: 20px;
                }
              }
            }
          }
          .footerButtons {
            margin-top: 20px;
            padding-right: 40px;
            display: flex;
            .btnReset,
            .btnApply {
              border: 1px solid $border-color;
              height: 50px;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0 10px;
              flex: 1;
              cursor: pointer;
              font-weight: 500;
            }
            .btnReset {
              flex: 0 0 40%;
              margin-right: 20px;
              background: #e7e7e7;
            }
          }
        }
      }
    }
  }
}
