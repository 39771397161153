.cenralBankSidebar {
  background: white;
  position: relative;
  .headTabBox {
    display: flex;
    height: 50px;
    border-bottom: 1px solid $border-color;
    .tab {
      width: 0;
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      &.true {
        background: whitesmoke;
        font-weight: 600;
      }
    }
  }
  .coinsList {
    .searchWraper {
      height: 55px;
      border: 1px solid $border-color;
      margin-bottom: 20px;
      display: flex;
      input {
        width: 100%;
        height: 100%;
        background: none;
        border: none;
        outline: none;
        padding: 0 30px;
      }
      .btnOption {
        flex: 0 0 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        &.true {
          font-weight: 600;
        }
      }
    }
    .btnChangeFee {
      position: absolute;
      height: 70px;
      background: $primary-color;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 21px;
      font-weight: 600;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
  .detailEdit {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 40px;
    flex: 1;
    overflow-y: auto;
    .coinShow {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .from,
      .to {
        margin: 20px 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        span {
          width: 120px;
          text-align: center;
          font-weight: 700;
          margin-bottom: 10px;
        }
        .scrollView {
          height: 130px !important;
          > div {
            display: flex;
            .box {
              margin-right: 10px;
              width: 120px;
              min-width: 110px;
              display: flex;
              flex-direction: column;
              align-items: center;
              border: 1px solid $border-color;
              img {
                width: 45px;
                height: 45px;
                margin: 20px 0;
              }
              .coinName {
                padding: 0 10px;
                margin-bottom: 10px;
                text-align: center;
              }
              &.true {
                border: 1px solid #8f8f8f;
              }
            }
          }
        }
      }
    }
    .name {
      margin: 30px 0 20px 0;
      font-weight: 500;
      font-size: 19px;
    }
    .inpWrap {
      height: 65px;
      border: 1px solid $border-color;
      display: flex;
      align-items: center;
      input {
        width: 0;
        flex: 1;
        height: 100%;
        padding: 0 30px;
        border: none;
        background: none;
        outline: none;
        font-size: 20px;
        font-weight: 600;
      }
      .btSpread {
        height: 50%;
        border: 1px solid $border-color;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 10px;
        margin-right: 10px;
        cursor: pointer;
      }
    }
  }
  .btnChangeFee {
    height: 70px;
    background: $primary-color;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 21px;
    font-weight: 600;
  }
}
