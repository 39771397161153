.godsEyeUserFiter {
  flex: 0 0 500px;
  max-width: 500px;
  border-left: 1px solid $border-color;
  display: flex;
  flex-direction: column;
  position: relative;
  .headCoin {
    flex: 0 0 90px;
    max-height: 90px;
    border-bottom: 1px solid $border-color;
    display: flex;
    padding: 0 20px;
    align-items: center;
    img {
      height: 50%;
    }
    .nameNdetail {
      flex: 1;
      padding: 0 15px;
      .name {
        font-size: 20px;
        font-weight: 600;
      }
      .spread {
        display: flex;
        font-size: 12px;
        align-items: center;
        span {
          margin: 0 2px;
          cursor: pointer;
          text-transform: capitalize;
          &:last-of-type {
            font-weight: 600;
            text-decoration: underline;
          }
        }
      }
    }
  }
  .headTabBox {
    display: flex;
    height: 60px;
    border-bottom: 1px solid $border-color;
    .btBack {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 0 0 50px;
      border-right: 1px solid $border-color;
      &:hover {
        * {
          zoom: 1.1;
        }
        background: $border-color;
      }
    }
    .tab {
      width: 0;
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        zoom: 1.1;
        font-weight: 600;
        background: $border-color;
      }
      &.true {
        background: whitesmoke;
        font-weight: 600;
      }
    }
  }
  .headTabs {
    display: flex;
    height: 70px;
    border-bottom: 1px solid $border-color;
    .tab {
      cursor: pointer;
      flex: 0 0 25%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      font-size: 18px;
      &.true {
        font-weight: 700;
        font-size: 20px;
        &::before {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 2px;
          background: $primary-color;
        }
      }
      &.false {
        font-weight: 500;
        opacity: 0.6;
      }
    }
  }
  .godsEyeFilterDisplay {
    flex: 1;
    padding: 35px;
    display: flex;
    flex-direction: column;
    .labelItm {
      font-weight: 500;
      color: $primary-color;
      margin-bottom: 10px;
    }
    .options {
      display: flex;
      height: 50px;
      margin-bottom: 30px;
      .option {
        width: 130px;
        border: 1px solid $border-color;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
        &.true {
          border-color: $primary-color;
        }
      }
    }
    .box {
      display: flex;
      height: 50px;
      width: 80%;
      margin-bottom: 20px;
      border: 1px solid $border-color;
      text-align: center;
      .label {
        flex: 0 0 32%;
        border-right: 1px solid $border-color;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .value {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 0;
        flex: 1;
        border: none;
        background: none;
        outline: none;
      }
    }
  }
  .godsEyeFilterCurrency {
    flex: 1;
    padding: 35px;
    display: flex;
    flex-direction: column;
    .labelItm {
      font-weight: 500;
      color: $primary-color;
      font-size: 20px;
      margin-bottom: 20px;
    }
    .searchBox {
      height: 65px;
      flex: 0 0 65px;
      border: 1px solid $border-color;
      display: flex;
      align-items: center;
      padding: 0 20px;
      margin-bottom: 20px;
      input {
        width: 0;
        flex: 1;
        border: none;
        background: none;
        outline: none;
      }
      .icon {
        font-size: 18px;
      }
      &.mb20 {
        margin-bottom: 20px;
        margin-top: 10px;
      }
    }
    .itemListScroll {
      height: 0 !important;
      flex: 1;
      > div {
        .item {
          height: 90px;
          display: flex;
          align-items: center;
          border: 1px solid $border-color;
          border-radius: 0;
          margin-bottom: 10px;
          padding: 0 20px;

          .coin {
            height: 55px;
            width: 55px;
            margin-right: 15px;
          }
          .checkbox {
            height: 20px;
            width: 20px;
            margin-left: auto;
          }
          .delete {
            cursor: pointer;
            height: 25px;
            width: 25px;
            margin-left: auto;
          }
          * {
            transition: all 300ms ease;
          }
          &:hover {
            * {
              transform: scale(1.1);
              transform-origin: 0;
            }
          }
        }
        .endPointLabel {
          font-size: 15px;
          font-weight: 700;
          display: flex;
          justify-content: space-between;
          line-height: 2;
        }
        .boxItem {
          display: flex;
          flex-direction: column;
          border: 1px solid $border-color;
          border-radius: 0;
          margin-bottom: 10px;
          padding: 15px;
          .copyBtn {
            font-size: 16px;
            transition: all 400ms ease;
            cursor: pointer;
            &:hover {
              transform: scale(1.05);
            }
          }
          .fieldDetail {
            display: flex;
            align-items: center;
            .icon {
              height: 46px;
              width: 46px;
              border-radius: 50%;
              margin-right: 10px;
            }
            .field {
              flex: 1;
              display: flex;
              flex-direction: column;
              .fieldName {
                font-size: 20px;
                font-weight: 700;
                display: flex;
              }
              .fieldType {
                font-size: 12px;
              }
              .fieldName,
              .fieldType {
                display: flex;
                justify-content: space-between;
              }
            }
          }
          .desc {
            margin-top: 4px;
            font-size: 11px;
            font-weight: 300;
            line-height: 1.8;
          }
          .fieldKey {
            font-size: 16px;
            font-weight: 500;
            display: flex;
            justify-content: space-between;
          }
          &:hover {
            background: whitesmoke;
          }
        }
        .valueBox {
          height: 55px;
          border: 1px solid $border-color;
          display: flex;
          align-items: center;
          padding: 0 20px;
          span {
            width: 0;
            flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
    .coinList {
      height: 0 !important;
      flex: 1;
      > div {
        display: flex;
        flex-direction: column;
        .coinItem {
          cursor: pointer;
          height: 90px;
          flex: 0 0 90px;
          border: 1px solid $border-color;
          display: flex;
          margin-bottom: 20px;
          align-items: center;
          padding: 0 30px;
          color: $primary-color;
          .coin {
            height: 30px;
            width: 30px;
            object-fit: contain;
            margin-right: 10px;
          }
          span {
            font-weight: 600;
            flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            color: $primary-color;
          }
          .value {
            margin-left: auto;
          }
          input[type="checkbox"] {
            margin-left: auto;
          }
          * {
            transition: all 300ms ease;
          }
          &:hover {
            background: whitesmoke;
            * {
              transform-origin: 0;
              transform: scale(1.1);
            }
            .nozoom {
              transform: none !important;
              zoom: 1 !important;
            }
          }
          &.disable {
            opacity: 0.4;
          }
        }
        .name {
          font-size: 19px;
          font-weight: 500;
        }
        .inputWrap {
          display: flex;
          height: 60px;
          border: 1px solid $border-color;
          margin: 20px 0 40px 0;
          .text {
            width: 0;
            flex: 1;
            border: none;
            padding: 0 30px;
          }
          .coinSym {
            height: 100%;
            padding: 0 20px;
            display: flex;
            align-items: center;
            border-left: 1px solid $border-color;
          }
          .fileInp {
            border-left: 1px solid $border-color;
            width: 60px;
            margin: 0;
            img {
              width: 100%;
              height: 100%;
              padding: 15px;
              &:hover {
                filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
              }
            }
            input {
              display: none;
            }
          }
          &.options {
            border: none;
            justify-content: space-between;
            .option {
              cursor: pointer;
              flex: 0 0 49%;
              border: 1px solid $border-color;
              display: flex;
              justify-content: center;
              align-items: center;
              &.true {
                box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.75);
              }
            }
          }
        }
        .btnSubmit {
          cursor: pointer;
          display: flex;
          margin: 20px 0 40px 0;
          border: 1px solid $primary-color;
          align-items: center;
          justify-content: center;
          font-weight: 600;
          padding: 14px;
          font-size: 20px;
          &:hover {
            background: $primary-color;
            color: white;
          }
        }
      }
    }
  }
  .footerBtns {
    height: 65px;
    border-top: 1px solid $border-color;
    display: flex;
    .btnReset,
    .btnSave {
      cursor: pointer;
      flex: 0 0 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      font-weight: 600;
      transition: all ease 300ms;
      &:hover {
        font-size: 22px;
      }
    }
    .btnReset {
      background: $primary-color;
      color: white;
    }
    .btnSee {
      cursor: pointer;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      font-weight: 600;
      background: #efefef;
      transition: all ease 300ms;
      &:hover {
        font-size: 22px;
      }
    }
  }
  .footerPage {
    flex: 0 0 80px;
    max-height: 80px;
    border-top: 1px solid $border-color;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    img {
      height: 50%;
      margin-right: 15px;
    }
    span {
      font-size: 22px;
      font-weight: 600;
    }
    &:hover {
      background: whitesmoke;
      zoom: 1.05;
    }
  }
  .userHeads {
    border-bottom: 1px solid $border-color;
    display: flex;
    height: fit-content;
    padding: 25px 20px;
    align-items: center;
    display: flex;
    img {
      width: 55px;
      height: 55px;
      border-radius: 50%;
      margin-right: 10px;
      overflow: hidden;
      border: 1px solid $border-color;
    }
    .detail {
      display: flex;
      flex-direction: column;
      flex: 1;
      .name {
        font-size: 21px;
        font-weight: 600;
        line-height: 1.2;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .email {
        text-transform: capitalize;
        font-size: 16px;
        display: flex;
        align-items: center;
        span {
          cursor: pointer;
          margin: 0 2px;
          &:last-of-type,
          &:hover {
            font-weight: 600;
            text-decoration: underline;
          }
        }
      }
    }
  }
  .userStats {
    flex: 1;
    padding: 30px;
    color: $primary-color;
    .userStatsTitle {
      font-size: 34px;
      font-weight: 600;
      line-height: 1;
    }
    .userCountBox {
      margin-top: 40px;
      height: 60px;
      border: 1px solid $border-color;
      display: flex;
      .label,
      .value {
        display: flex;
        flex: 0 0 50%;
        align-items: center;
        font-weight: 600;
      }
      .label {
        padding-left: 20px;
        border-right: 1px solid $border-color;
      }
      .value {
        justify-content: center;
      }
    }
  }
  .btnSubmit {
    flex: 0 0 60px;
    background: $primary-color;
    color: white;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
  }
  .ftBtns {
    height: 70px;
    border-top: 1px solid $border-color;
    display: flex;
    .newField,
    .btnSubmit {
      flex: 0 0 50%;
      background: white;
      color: $primary-color;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;
    }
    .newField {
    }
    .btnSubmit {
      border-left: 1px solid $border-color;
      @include btn-animate;
    }
  }

  //
  .titleOp {
    margin: 30px 0;
    font-weight: 600;
    font-size: 21px;
  }
  .searchWrap {
    height: 75px;
    border: 1px solid $border-color;
    display: flex;
    margin: 0;
    input {
      flex: 1;
      border: none;
      background: none;
      padding: 0 30px;
    }
  }
  .searchList {
    height: 0 !important;
    width: unset !important;
    flex: 1;
    border: 1px solid $border-color;
    border-style: none solid;
    margin: 0;
    > div {
      display: flex;
      flex-direction: column;
      .user {
        flex: 0 0 110px;
        max-height: 110px;
        border-bottom: 1px solid $border-color;
        display: flex;
        align-items: center;
        padding: 0 30px;
        cursor: pointer;
        * {
          transition: all 400ms ease;
        }
        img {
          height: 44px;
          width: 44px;
          border-radius: 22px;
        }
        .userDetail {
          margin-left: 15px;
          .name {
            font-size: 20px;
            font-weight: 600;
          }
          .email {
            font-size: 17px;
          }
        }
        &:hover {
          * {
            transform: scale(1.03);
          }
        }
        &.true {
          background: whitesmoke;
        }
      }
      .bond {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid $border-color;
        .coinDetail {
          display: flex;
          padding: 20px 30px;
          align-items: center;
          .dp {
            height: 40px;
            width: 40px;
            margin-right: 10px;
          }
          .name {
            font-size: 22px;
            font-weight: 600;
          }
        }
        .bondValues {
          display: flex;
          padding: 20px 30px;
          padding-top: 0;
          align-items: center;
          justify-content: space-between;
          .cost,
          .intrst,
          .days {
            font-size: 18px;
            font-weight: 600;
          }
        }
        > * {
          transition: all 400ms ease;
        }
        &:hover {
          > * {
            transform: scale(1.03);
          }
        }
        &.true {
          background: whitesmoke;
        }
      }
    }
  }
}
.header {
  display: flex;
  padding: 35px;
  .content {
    flex: 1;
    .title {
      font-size: 28px;
      font-weight: 600;
      display: flex;
      align-items: center;
      img {
        height: 28px;
        width: 28px;
        object-fit: contain;
        margin-right: 6px;
      }
    }
    .breadCrumbs {
      span {
        margin-top: 6px;
        font-size: 14px;
        cursor: pointer;
        &:last-of-type {
          font-weight: 600;
          text-decoration: underline;
        }
        &:hover {
          font-weight: 500;
        }
      }
    }
  }
  .backBtn,
  .closeBtn {
    height: 50px;
    width: 50px;
    color: white;
    font-size: 30px;
    line-height: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 400ms ease;
    border: 1px solid $border-color;
    background: white !important;
    margin-left: 8px;
    img {
      height: 20px;
      width: 20px;
      transition: all 400ms ease;
      filter: brightness(0);
    }
    &:hover {
      img {
        transform: scale(1.2);
      }
    }
  }
}
