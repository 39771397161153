.godsEyeMoreList {
  flex: 1;
  display: flex;
  flex-direction: column;
  .moreListWrapper {
    height: 0;
    flex: 1;
    > div {
      .listItem {
        cursor: pointer;
        height: 120px;
        border-bottom: 1px solid $border-color;
        padding-left: 40px;
        padding-right: 90px;
        display: flex;
        align-items: center;
        * {
          transition: all ease 300ms;
        }
        .profile {
          flex: 0 0 45%;
          display: flex;
          align-items: center;
          .icon {
            height: 50px;
            width: 50px;
          }
          .name {
            margin-left: 10px;
            font-size: 22px;
            font-weight: 600;
          }
        }
        .icon {
          height: 40px;
          width: 40px;
          object-fit: contain;
        }
        .name {
          margin-left: 10px;
          font-size: 22px;
          font-weight: 600;
          flex: 1;
        }
        .count {
          font-size: 22px;
          font-weight: 600;
        }
        .btnWebsite {
          margin-left: auto;
          padding: 15px 30px;
          border: 1px solid $border-color;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          &:hover {
            background: $primary-color;
            color: white;
            font-weight: 600;
          }
        }
        .createdBy {
          display: flex;
          margin-left: auto;
          align-items: center;
          .label {
            font-size: 16px;
            font-weight: 500;
            margin-right: 20px;
          }
          .icn {
            height: 40px;
            width: 40px;
            object-fit: contain;
          }
          .value {
            font-size: 18px;
            font-weight: 600;
            margin-left: 20px;
            text-decoration: underline;
          }
        }
        &:hover {
          * {
            transform: scale(1.03);
            transform-origin: 0;
          }
        }
        &.true {
          background: whitesmoke;
        }
      }
      .listItemTerminal {
        cursor: pointer;
        height: 120px;
        border-bottom: 1px solid $border-color;
        padding-left: 40px;
        padding-right: 90px;
        display: flex;
        align-items: center;
        * {
          transition: all ease 300ms;
        }
        .imgWrap {
          flex: 0 0 31%;
          .icon {
            height: 40px;
            width: auto;
            max-width: 90%;
            object-fit: contain;
          }
        }
        .fund {
          flex: 0 0 18%;
          font-size: 19px;
          font-weight: 600;
        }
        .email {
          flex: 0 0 27%;
          font-size: 19px;
          font-weight: 400;
        }
        .btnTerminal,
        .btnWebsite {
          border: 1px solid $border-color;
          padding: 15px 3%;
          @include btn-animate();
        }
        .btnTerminal {
          margin-left: auto;
        }
        .btnWebsite {
          margin-left: 20px;
        }
        &:hover {
          * {
            transform: scale(1.03);
            transform-origin: 0;
          }
        }
        &.true {
          background: whitesmoke;
        }
      }
      .userItem {
        height: 120px;
        opacity: 0.5;
        cursor: pointer;
        border-bottom: 1px solid $border-color;
        background: white;
        padding: 0 70px;
        display: flex;
        align-items: center;
        .profileImg {
          height: 34px;
          width: 34px;
          border-radius: 17px;
        }
        .cpyIcn {
          font-size: 0.8em;
          margin-left: 0.5em;
          &:hover {
            transform: scale(1.1);
          }
        }
        .nameEmail {
          margin-left: 15px;
          flex: 0 0 19%;
          display: flex;
          flex-direction: column;
          line-height: 1;
          margin-right: auto;
          &.sm {
            flex: 0 0 14%;
          }
          .name {
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 4px;
          }
          .email {
            font-size: 13px;
            font-weight: normal;
          }
        }
        .time,
        .balance {
          margin-left: 15px;
          flex: 0 0 20%;
        }

        .btnActions {
          margin-left: auto;
          display: flex;
          .btnAction {
            cursor: pointer;
            width: 110px;
            height: 35px;
            border: 1px solid $border-color;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 15px;
            &.more {
              width: 135px;
              justify-content: space-between;
              padding: 0 20px;
              position: relative;
              svg {
                color: gray;
              }
              .dropList {
                position: absolute;
                left: -1px;
                right: -1px;
                top: 100%;
                display: flex;
                flex-direction: column;
                border: 1px solid $border-color;
                .dropItm {
                  cursor: pointer;
                  border-top: 1px solid $border-color;
                  background: white;
                  height: 40px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  z-index: 2;
                  &:first-child {
                    border: none;
                  }
                  &:hover {
                    background: whitesmoke;
                  }
                }
              }
            }
          }
        }
        &:hover {
          background: whitesmoke;
          filter: drop-shadow(1);
          zoom: 1.03;
        }
        &.true {
          opacity: 1;
        }
      }
    }
    &.true {
      .hidOnSelect {
        display: none;
      }
    }
  }
}
