@import "../../static/scss/colors";
@import "../../static/scss/mixin";

.blockChainTabContent {
  width: 0;
  flex: 1;
  padding: 15px 25px;
  display: flex;
  flex-direction: column;
  .coinList {
    flex: 0 0 180px;
    div {
      display: flex;
      .coin {
        flex: 0 0 180px;
        flex-direction: column;
        border: 1px solid $border-color;
        margin-right: 20px;
        justify-content: center;
        align-items: center;
        max-width: 180px;
        img {
          height: 40%;
          width: auto;
          margin-bottom: 15%;
        }
        span {
          font-size: 16px;
          text-align: center;
        }
        &:hover {
          * {
            transform: scale(1.05);
            font-weight: 600;
          }
        }
        &.true {
          border: 1px solid black;
          * {
            transform: scale(1.05);
            font-weight: 600;
          }
        }
      }
    }
  }
  .coinDetail {
    margin-top: 15px;
    flex: 1;
    border: 1px solid $border-color;
    display: flex;
    flex-direction: column;
    .listHeadData {
      flex: 0 0 60px;
      border-bottom: 1px solid $border-color;
      display: flex;
      .userCounts,
      .assetsOnNetwork {
        display: flex;
        justify-content: center;
        align-items: center;
        border-right: 1px solid $border-color;
        font-size: 20px;
        font-weight: 600;
        padding: 0 30px;
      }
      .assetsAmounts {
        border-right: 1px solid $border-color;
        padding: 0 20px;
        display: flex;
        .asset {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0 30px;
          font-size: 20px;
          font-weight: 500;
          .coin {
            height: 22px;
            border: 1px solid $border-color;
            font-size: 14px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 4px;
            margin-left: 10px;
            img {
              height: 12px;
              width: 12px;
              margin-right: 4px;
            }
          }
        }
      }
      .search {
        border: none;
        background: none;
        flex: 1;
        padding: 0 20px;
      }
      .btnOpenClose {
        border-left: 1px solid $border-color;
        flex: 0 0 60px;
        font-size: 22px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .filterRow {
      display: flex;
      flex: 0 0 56px;
      border-bottom: 1px solid $border-color;
      .label,
      .value {
        border-right: 1px solid $border-color;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        font-weight: 600;
      }
      .label {
        flex: 0 0 18%;
      }
      .value {
        flex: 0 0 32%;
      }
    }
    .userList {
      height: 0 !important;
      flex: 1;
      > div {
        .userItem {
          height: 90px;
          border-bottom: 1px solid $border-color;
          padding: 0 40px;
          display: flex;
          .user {
            display: flex;
            align-items: center;
            flex: 0 0 26%;
            .profileImg {
              height: 46px;
              width: 46px;
              border-radius: 23px;
            }
            .nameEmail {
              margin-left: 15px;
              flex: 0 0 19%;
              display: flex;
              flex-direction: column;
              line-height: 1;
              margin-right: auto;
              .name {
                font-size: 16px;
                font-weight: 600;
                margin-bottom: 4px;
              }
              .email {
                font-size: 13px;
                font-weight: normal;
              }
            }
          }
          .app {
            display: flex;
            align-items: center;
            flex: 0 0 16%;
            img {
              height: 20px;
              width: 20px;
              object-fit: contain;
              margin-right: 10px;
            }
            .appName {
              font-size: 17px;
              font-weight: 600;
            }
          }
          .addressWrap {
            flex: 0 0 23%;
            display: flex;
            align-items: center;
            padding: 0 30px;
            .address {
              flex: 1;
              height: 36px;
              border: 1px solid $border-color;
              display: flex;
              align-items: center;
              .value {
                width: 0;
                flex: 1;
                overflow: hidden;
                text-overflow: ellipsis;
                padding: 0 20px;
              }
              .box {
                height: 100%;
                flex: 0 0 36px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-left: 1px solid $border-color;
                cursor: pointer;
                svg {
                  height: 55%;
                  width: 55%;
                  object-fit: contain;
                  &:hover {
                    transform: scale(1.1);
                  }
                }
              }
            }
          }
          .assetsAmounts {
            flex: 1;
            padding: 0 20px;
            display: flex;
            justify-content: space-between;
            .asset {
              flex: 0 0 48%;
              display: flex;
              justify-content: start;
              align-items: center;
              padding: 0 30px;
              font-size: 20px;
              font-weight: 500;
              .coin {
                height: 22px;
                border: 1px solid $border-color;
                font-size: 14px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 4px;
                margin-left: 10px;
                img {
                  height: 12px;
                  width: 12px;
                  margin-right: 4px;
                }
              }
            }
          }
        }
      }
    }
  }
}
