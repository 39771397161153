.enterPinUnlock {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 5;
  display: flex;
  .overlayClose {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.85);
  }
  .pinCard {
    z-index: 1;
    background-color: white;
    height: 55%;
    width: 90%;
    max-width: 480px;
    margin: auto;
    border-radius: 30px;
    border: 1px solid $primary-color;
    display: flex;
    flex-direction: column;
    color: $primary-color;
    overflow: hidden;
    position: relative;
    .loadingAnim {
      position: absolute;
      background: #ffffffe0;
      display: flex;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
    }
    .inCard {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding: 70px 0;
      .logo {
        width: 40%;
        margin-bottom: 3%;
        max-height: 200px;
      }
      .title {
        font-size: 28px;
        font-weight: 600;
      }
      .otp-input-wrapper {
        width: 80%;
        justify-content: space-between;
        > div {
          flex: 0 0 20%;
          height: 90px;
          .otp-input {
            width: 100% !important;
            height: 100%;
            border: 2px solid $border-color;
            border-radius: 8px;
            font-size: 28px;
          }
        }
      }
    }
    .btnCloseFooter {
      background: $primary-color;
      width: 100%;
      height: 60px;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
      font-size: 21px;
      font-weight: 600;
    }
  }
}
