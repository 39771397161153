.listsFooter {
  height: 64px;
  flex: 0 0 64px;
  border-top: 1px solid $border-color;
  display: flex;
  .filterBox {
    flex: 0 0 260px;
    border-right: 1px solid $border-color;
    position: relative;
    font-size: 20px;
    padding: 0 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .vertScrolllList {
    width: 0;
    flex: 1;
    display: flex;
    overflow-x: scroll;
    .listItem {
      flex: 0 0 240px;
      width: fit-content;
      display: flex;
      align-items: center;
      padding: 0 25px;
      opacity: 0.4;
      .icon {
        height: 30px;
        width: 30px;
        background: rebeccapurple;
        margin-right: 20px;
      }
      span {
        font-weight: 600;
      }
      &.true {
        opacity: 1;
      }
    }
  }
  .countBox {
    flex: 0 0 64px;
    border-left: 1px solid $border-color;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
  }
}
