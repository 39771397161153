.viewingAs {
  height: 60px;
  padding: 0 0 0 70px;
  display: flex;
  line-height: 1;
  border-bottom: 1px solid $border-color;
  align-items: center;
  position: relative;
  .textViewingAs {
    font-size: 14px;
    margin-right: 10px;
  }
  .profile {
    height: 30px;
    width: 30px;
    border-radius: 15px;
    margin-right: 5px;
  }
  .name {
    font-size: 18px;
    font-weight: 600;
  }
  .btnRefresh {
    margin-left: auto;
    border: 1px solid $border-color;
    height: 40px;
    border-radius: 6px;
    display: flex;
    cursor: pointer;
    padding: 0 20px;
    align-items: center;
    font-weight: 600;
    margin-right: 70px;
  }
  .searchInp {
    height: 100%;
    flex: 1;
    border: none;
    background: none;
  }
  .btnType {
    cursor: pointer;
    border-left: 1px solid $border-color;
    height: 100%;
    flex: 0 0 13%;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    &.true {
      background: #e7e7e7;
      font-weight: 600;
    }
  }
  .userList {
    background: white;
    position: absolute;
    left: 0;
    right: 0;
    top: 61px;
    z-index: 11;
    max-height: 400%;
    overflow-y: auto;
    border-bottom: 1px solid $border-color;
    .userItem {
      background: white;
      height: 60px;
      padding: 0 70px;
      display: flex;
      align-items: center;
      .profileImg {
        height: 34px;
        width: 34px;
        border-radius: 17px;
      }
      .name {
        margin-left: 15px;
        flex: 0 0 20%;
      }
      .email {
        flex: 0 0 20%;
      }
      .nameEmail {
        font-weight: 500;
      }
      .btnActions {
        margin-left: auto;
        display: flex;
        .btnAction {
          width: 100px;
          height: 30px;
          border: 1px solid $border-color;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 15px;
        }
      }
    }
    .userItemWithdraw {
      border-bottom: 1px solid $border-color;
      background: white;
      height: 70px;
      padding: 0 70px;
      display: flex;
      align-items: center;
      .profileImg {
        height: 34px;
        width: 34px;
        border-radius: 17px;
      }
      .email {
        margin-left: 15px;
        flex: 0 0 23%;
      }
      .date {
        flex: 0 0 23%;
      }
      .value {
        flex: 0 0 23%;
      }
      .actions {
        height: 100%;
        margin-left: auto;
        flex: 0 0 22%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .btnAction {
          cursor: pointer;
          margin-left: 20px;
          flex: 0 0 25%;
          height: 50%;
          font-size: 14px;
          border: 1px solid $border-color;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
  @include xxl {
    zoom: 0.95;
  }
  @include xl {
    zoom: 0.9;
    .btnType {
      flex: 0 0 14%;
    }
  }
  @include lg {
    .btnType {
      flex: 0 0 15%;
    }
  }
}
