$width: 400px;

.loginPage {
  height: 100%;
  max-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: $nvest-color;
  .loginWrap {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 60px;
    background: white;
    .logo {
      width: $width;
    }
    .login-form {
      width: $width;
      .twoFaLabel {
        margin: 30px 0;
        text-align: center;
        font-size: 22px;
      }
      .succesMsg {
        margin: 55px 0;
        text-align: center;
        font-size: 22px;
        line-height: 2;
      }
      .otp-input-wrapper {
        margin: 0 auto;
        display: flex;
        height: 90px;
        div {
          flex-grow: 1;
          border: 1px solid $nvest-color;
          margin: 3px;
          .otp-input {
            width: 90% !important;
            height: 50px;
            margin-left: auto !important;
            margin-right: auto !important;
            background: white;
            border-radius: 5px;
            border: none;
            outline: none;
            font-size: 45px;
            color: $nvest-color;
            opacity: 0.2;
            text-shadow: 0 0 0 $nvest-color;
            font-family: "Montserrat", sans-serif;
            box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
          }
          input[type="number"]::-webkit-inner-spin-button {
            -webkit-appearance: none;
          }
          input[type="number"] {
            -moz-appearance: textfield;
            appearance: textfield;
            margin: 0;
          }
        }
      }
      .group {
        position: relative;
        margin: 0;
        margin-top: 80px;
        display: flex;
        input,
        button {
          width: 100%;
        }
        button {
          cursor: pointer;
          height: 55px;
          border: 1px solid $nvest-color;
          border-radius: 0;
          background: $nvest-color;
          color: white;
          font-size: 22px;
          &:hover {
            background: white;
            color: $nvest-color;
          }
        }
        input[type="password"],
        input[type="text"] {
          background: none;
          color: #505050;
          font-size: 18px;
          padding: 10px 10px 10px 0;
          display: block;
          border: none;
          border-radius: 0;
          border-bottom: 1px solid #505050;
          &:focus {
            outline: none;
          }
          &:focus ~ label,
          &:valid ~ label {
            top: -14px;
            font-size: 12px;
            color: #00000070;
            font-weight: 700;
          }
        }

        input[type="password"] {
          letter-spacing: 0.3em;
        }
        label {
          color: #00000050;
          font-size: 16px;
          font-weight: normal;
          position: absolute;
          pointer-events: none;
          left: 0;
          top: 10px;
          transition: 300ms ease all;
        }
        .eye {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 15px;
          margin: auto;
          color: $nvest-color;
        }
      }
    }
  }
  &.society {
    background: $primary-color;
    .login-form {
      .otp-input-wrapper {
        div {
          border: 1px solid $primary-color;
          .otp-input {
            color: $primary-color;
            text-shadow: 0 0 0 $primary-color;
          }
        }
      }
      .group {
        button {
          border: 1px solid $primary-color;
          background: $primary-color;
          &:hover {
            color: $primary-color;
          }
        }
        .eye {
          color: $primary-color;
        }
      }
    }
  }
  @include md {
    .loginWrap {
      .logo {
        height: 18%;
      }
      .login-form {
        width: 80%;
      }
    }
  }
}
