.TokenApps {
  .xidView {
    height: 0;
    flex: 1;
    display: flex;
    .xidApps {
      width: 0;
      flex: 1;
      display: flex;
      .appsScrollbar {
        width: 0 !important;
        flex: 1;
        > div {
          .groupItem {
            height: fit-content;
            border-bottom: 1px solid $border-color;
            padding: 20px 93.33px 20px 50px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            opacity: 0.4;
            cursor: pointer;
            .topItem {
              display: flex;
              align-items: center;
              text-transform: capitalize;
              flex-basis: 100%;
              .icon {
                height: 52px;
                width: 52px;
                border-radius: 26px;
                margin-right: 20px;
              }
              .copyBtn {
                margin: auto 10px;
                font-size: 16px;
                transition: all 400ms ease;
                cursor: pointer;
                &:hover {
                  transform: scale(1.05);
                }
              }
              .group {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                .grpName {
                  font-size: 30px;
                  font-weight: 700;
                  line-height: 1.7;
                  display: flex;
                  align-items: center;
                }
                .grpFor {
                  font-size: 14px;
                  display: flex;
                  align-items: center;
                }
              }
              .collection {
                margin-left: auto;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                .grpId {
                  font-size: 20px;
                  font-weight: 600;
                  display: flex;
                  align-items: center;
                }
                .colectionName {
                  font-size: 14px;
                  display: flex;
                  align-items: center;
                }
              }
            }
            .desc {
              flex-basis: 95%;
              margin: 0;
              margin-top: 13px;
              line-height: 1.8;
              font-weight: 400;
            }
            &:hover {
              background: whitesmoke;
            }
            &.true {
              opacity: 1;
            }
          }
        }
      }
      .appDetail {
        width: 0;
        flex: 1;
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        .groupSelected {
          flex: 1;
          padding: 20px 4%;
          padding-bottom: 0;
          display: flex;
          flex-direction: column;
          .topItem {
            display: flex;
            align-items: center;
            text-transform: capitalize;
            .icon {
              height: 66px;
              width: 66px;
              border-radius: 33px;
              margin-right: 20px;
            }
            .group {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              .grpName {
                font-size: 24px;
                font-weight: 600;
              }
              .grpFor {
                font-size: 18px;
              }
            }
            .collection {
              margin-left: auto;
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              .grpId {
                font-size: 22px;
                font-weight: 600;
              }
              .colectionName {
                font-size: 18px;
              }
            }
          }
          .desc {
            margin: 0;
            margin-top: 1em;
          }
          .feedTitle {
            padding: 10px 0;
            font-size: 22px;
            font-weight: 600;
          }
          .fieldLists {
            height: 0 !important;
            flex: 1;
            .fieldItem {
              height: fit-content;
              border: 1px solid $border-color;
              padding: 20px 4%;
              margin: 12px 0;
              .topItem {
                display: flex;
                align-items: center;
                text-transform: capitalize;
                .icon {
                  height: 46px;
                  width: 46px;
                  border-radius: 23px;
                  margin-right: 10px;
                }
                .group {
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  .grpName {
                    font-size: 22px;
                    font-weight: 600;
                  }
                  .grpFor {
                    font-size: 16px;
                  }
                }
                .collection {
                  margin-left: auto;
                  display: flex;
                  flex-direction: column;
                  align-items: flex-end;
                  .grpId {
                    font-size: 20px;
                    font-weight: 600;
                  }
                  .colectionName {
                    font-size: 16px;
                  }
                }
              }
              .desc {
                margin: 0;
                margin-top: 1em;
                font-size: 15px;
              }
            }
          }
        }
      }
    }
  }
}
