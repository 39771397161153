.bankerTxnSidebar {
  flex: 0 0 52%;
  height: 100%;
  border-left: 1px solid $border-color;
  display: flex;
  flex-direction: column;
  .tabs {
    display: flex;
    height: 50px;
    .tab {
      flex: 0 0 25%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid $border-color;
      position: relative;
      opacity: 0.5;
      &:before {
        content: "";
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: 0;
        height: 2px;
        background: black;
        position: absolute;
        transition: all 300ms ease;
      }
      &:hover {
        opacity: 0.8;
        font-weight: 500;
        &:before {
          width: 100%;
          background: $primary-color;
        }
      }
      &.true {
        opacity: 1;
        font-weight: 600;
        &:before {
          width: 100%;
        }
      }
    }
  }
  .heads {
    height: 120px;
    padding: 40px 80px 30px 80px;
    align-items: center;
    display: flex;
    img {
      width: 70px;
      height: 70px;
      border-radius: 50%;
      margin-right: 30px;
      overflow: hidden;
      border: 1px solid $border-color;
    }
    .detail {
      display: flex;
      flex-direction: column;
      flex: 1;
      .name {
        font-size: 21px;
        font-weight: 600;
        line-height: 1.2;
        display: flex;
        align-items: center;
        justify-content: space-between;
        svg {
          cursor: pointer;
          font-size: 18px;
          margin-left: 6px;
          &:hover {
            transform: scale(1.2);
            filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.6));
          }
        }
      }
      .email {
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        svg {
          cursor: pointer;
          font-size: 18px;
          margin-left: 6px;
          &:hover {
            transform: scale(1.2);
            filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.6));
          }
        }
      }
    }
  }
  .userDetail {
    height: 0;
    flex: 1;
    overflow: scroll;
    > div {
      padding: 0 80px;
      .listItem {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;
        span {
          font-size: 17px;
          display: flex;
          align-items: center;
          svg {
            cursor: pointer;
            font-size: 18px;
            margin-left: 6px;
            &:hover {
              transform: scale(1.2);
              filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.6));
            }
          }
        }
      }
      .moreTitle {
        font-size: 25px;
        font-weight: 600;
        margin-top: 20px;
      }
      .moreItem {
        height: 100px;
        border: 1px solid $border-color;
        margin: 15px 0;
        padding: 0 40px;
        justify-content: space-between;
        display: flex;
        align-items: center;
        cursor: pointer;
        .user {
          display: flex;
          align-items: center;
          img {
            height: 55px;
            width: 55px;
            border-radius: 50%;
            margin-right: 15px;
          }
        }
        .detail {
          .name {
            font-size: 22px;
            font-weight: 600;
            line-height: 1;
          }
          .desc {
            font-size: 14px;
          }
        }
        .dd {
          font-size: 19px;
          font-weight: 600;
        }
        &:hover {
          background: whitesmoke;
          * {
            zoom: 1.05;
          }
        }
      }
    }
  }
  .breadCrumb {
    padding: 25px 40px;
    display: flex;
    span {
      cursor: pointer;
      margin: 0 4px;
      &:last-child {
        font-weight: 600;
      }
      &:hover {
        font-weight: 600;
      }
    }
  }
  .apiView {
    height: 0;
    flex: 1;
    padding: 40px;
    position: relative;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    .head {
      font-size: 21px;
      font-weight: 600;
    }
    .scrollbar {
      margin-top: 15px;
      height: 0 !important;
      flex: 1;
      > div {
        .item {
          cursor: pointer;
          display: flex;
          border: 1px solid $border-color;
          padding: 0 30px;
          height: 100px;
          margin-bottom: 20px;
          align-items: center;
          transition: all ease 300ms;
          img {
            width: 30px;
            height: 30px;
            margin-right: 15px;
          }
          span {
            font-weight: 500;
            font-size: 19px;
          }
          * {
            transition: all ease 300ms;
          }
          &:hover {
            * {
              transform: scale(1.05);
            }
          }
        }
      }
    }
  }
  .jsonScroll {
    position: relative;
    height: 0;
    flex: 1;
    > div {
      padding: 20px;
    }
    .btClose {
      cursor: pointer;
      z-index: 3;
      position: absolute;
      top: 20px;
      right: 20px;
      height: 26px;
      width: 26px;
      transition: all ease 300ms;
      &:hover {
        transform: scale(1.05);
      }
    }
  }
  .footers {
    margin-top: auto;
    display: flex;
    height: 80px;
    border-top: 1px solid $border-color;
    .content {
      width: 0;
      flex: 1;
      .menuList {
        > div {
          display: flex;
          .menuItem {
            padding: 0 20px;
            border: 1px solid $border-color;
            flex: 0 0 200px;
            display: flex;
            align-items: center;
            cursor: pointer;
            img {
              height: 30px;
              width: 30px;
              margin-right: 15px;
            }
            span {
              font-size: 20px;
              font-weight: 500;
              white-space: nowrap;
            }
            * {
              transition: ease all 300ms;
            }
            &:hover {
              * {
                transform: scale(1.05);
              }
            }
            &.true {
              span {
                font-weight: 600;
              }
            }
          }
        }
      }
    }
    .icon {
      flex: 0 0 80px;
      border-left: 1px solid $border-color;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      img {
        height: 45%;
        width: 45%;
      }
      &:hover {
        background: whitesmoke;
        img {
          transform: scale(1.05);
        }
      }
    }
  }
}
