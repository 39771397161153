.landingLottie {
  display: flex;
  justify-content: center;
  align-content: center;
  position: absolute;
  z-index: 1;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
