.navHeader {
  .appListWrapper {
    margin-left: 5%;
    > div {
      display: flex;
      align-items: center;
      .app {
        cursor: pointer;
        flex: 0 0 18%;
        height: 12 0px;
        border: 1px solid $border-color;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 400ms ease;
        height: 65%;
        margin-right: 20px;
        img {
          height: 40%;
          width: 55%;
          object-fit: contain;
          transition: all 400ms ease;
        }
        &.space {
          border: none;
          background: none;
        }
        &:hover {
          background: whitesmoke;
          img {
            transform: scale(1.1);
          }
        }
      }
    }
    @include md {
      display: none;
    }
  }
}
.recieptAppContent {
  height: 0;
  flex: 1;
  display: flex;
  .contentArea {
    flex: 1;
    padding: 0 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .logo {
      width: 80%;
      height: 16%;
      object-fit: contain;
      object-position: left bottom;
    }
    .desc {
      font-size: 18px;
      margin: 40px 0;
    }
    .search {
      height: 70px;
      border: 1px solid $border-color;
      flex: 0 0 70px;
      border-radius: 28px;
      padding: 0 40px;
      display: flex;
      align-items: center;
      font-size: 20px;
      .textInput {
        flex: 1;
        font-size: 16px;
        border: none;
        background: none;
        display: flex;
        align-items: center;
      }
      svg {
        height: 21px;
        width: 21px;
        margin-right: 20px;
        path {
          fill: black;
        }
      }
    }
  }
  .bgImg {
    object-fit: cover;
    height: 100%;
    width: 50%;
  }
  @include md {
    .contentArea {
      width: 0;
      padding: 0 4%;
      .search {
        height: 65px;
        flex: 0 0 65px;
        border-radius: 25px;
        padding: 0 20px;
        margin-bottom: 30%;
        .textInput {
          font-size: 15px;
        }
        svg {
          height: 17px;
          width: 17px;
          margin-right: 10px;
        }
      }
    }
    .bgImg {
      display: none;
    }
  }
}
