.godsEyeUsers {
  flex: 1;
  display: flex;
  flex-direction: column;
  .userCtrls {
    height: 80px;
    border-bottom: 1px solid $border-color;
    display: flex;
    .serchInp {
      border: none;
      border: none;
      padding: 0 50px;
      flex: 1;
    }
    .moreFilter {
      width: 210px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-left: 1px solid $border-color;
      cursor: pointer;
      &:hover {
        background: whitesmoke;
      }
    }
  }
  .userListWrapper {
    height: 0 !important;
    flex: 1;
    .userList {
      display: flex;
      flex-direction: column;
      .userItem {
        opacity: 0.5;
        cursor: pointer;
        border-bottom: 1px solid $border-color;
        background: white;
        flex: 0 0 16.66%;
        padding: 0 70px;
        display: flex;
        align-items: center;
        .profileImg {
          height: 34px;
          width: 34px;
          border-radius: 17px;
        }
        .cpyIcn {
          font-size: 0.8em;
          margin-left: 0.5em;
          &:hover {
            transform: scale(1.1);
          }
        }
        .nameEmail {
          margin-left: 15px;
          flex: 0 0 19%;
          display: flex;
          flex-direction: column;
          line-height: 1;
          &.sm {
            flex: 0 0 14%;
          }
          .name {
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 4px;
          }
          .email {
            font-size: 13px;
            font-weight: normal;
          }
        }
        .time,
        .balance {
          margin-left: 15px;
          flex: 0 0 20%;
        }

        .btnActions {
          margin-left: auto;
          display: flex;
          .btnAction {
            cursor: pointer;
            width: 110px;
            height: 35px;
            border: 1px solid $border-color;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 15px;
            &.more {
              width: 135px;
              justify-content: space-between;
              padding: 0 20px;
              position: relative;
              svg {
                color: gray;
              }
              .dropList {
                position: absolute;
                left: -1px;
                right: -1px;
                top: 100%;
                display: flex;
                flex-direction: column;
                border: 1px solid $border-color;
                .dropItm {
                  cursor: pointer;
                  border-top: 1px solid $border-color;
                  background: white;
                  height: 40px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  z-index: 2;
                  &:first-child {
                    border: none;
                  }
                  &:hover {
                    background: whitesmoke;
                  }
                }
              }
            }
          }
        }
        &:hover {
          background: whitesmoke;
          filter: drop-shadow(1);
          zoom: 1.03;
        }
        &.true {
          opacity: 1;
        }
      }
      .fxClose {
        flex: 1;
      }
      &.true {
        .nameEmail {
          flex: 0 0 45%;
        }
        .userItem {
          .hideOpen {
            display: none;
          }
        }
      }
    }
  }
}
