:root {
  --primary-color: #484848;
  --secondary-color: #484848;
}
$primary-color: var(--primary-color);
$secondary-color: var(--secondary-color);
$border-color: #e7e7e780;
.viewSwitcher {
  display: flex;
  width: 280px;
  .btSwitchView {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.6;
    color: $primary-color;
    width: 0;
    flex: 1;
    border-left: 1px solid $border-color;
    svg {
      height: 22px;
      width: 22px;
      margin-right: 6px;
      path {
        fill: $primary-color;
      }
    }
    &:hover {
      opacity: 0.8;
      svg {
        transform: scale(1.06);
      }
    }
    &.false {
      opacity: 0.5;
    }
    &.true {
      opacity: 1;
      font-weight: 600;
      background: $primary-color;
      svg {
        path {
          fill: white;
        }
      }
    }
  }
}
