.mainLayout {
  display: flex;
  height: 100%;
  min-height: 100vh;
  .siteLayout {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  &.hide {
    display: none;
  }
}
