.assetIoBalances {
  border-bottom: 1px solid $border-color;
  padding: 0 70px;
  display: flex;
  .balance {
    flex: 0 0 25%;
    padding: 15px 0;
    .value {
      font-size: 20px;
      font-weight: 600;
      line-height: 1;
    }
    .label {
      padding: 2px 0;
      font-size: 12px;
      line-height: 1;
    }
  }
}
