.blockCheckInbox {
  height: 0;
  flex: 1;
  display: flex;
  .inboxList {
    width: 0 !important;
    flex: 1;
    > div {
      .inboxItm {
        cursor: pointer;
        height: 140px;
        border-bottom: 1px solid $border-color;
        padding: 0 10%;
        display: flex;
        align-items: center;
        .mailId {
          font-size: 20px;
          font-weight: 500;
        }
        &:hover,
        &.true {
          background: whitesmoke;
        }
      }
    }
  }
}
