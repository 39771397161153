.bankerList {
  flex: 1;
  display: flex;
  flex-direction: column;
  .bankerListWrapper {
    height: 0 !important;
    flex: 1;
    .xchangeList {
      display: flex;
      flex-direction: column;
      .userItem {
        justify-content: space-between;
        .cpyIcn {
          font-size: 0.8em;
          margin-left: 0.5em;
          &:hover {
            transform: scale(1.1);
          }
        }
        cursor: pointer;
        border-bottom: 1px solid $border-color;
        background: white;
        flex: 0 0 16.66%;
        padding: 0 70px;
        display: flex;
        align-items: center;
        opacity: 0.3;
        .logoImg {
          height: 34px;
          width: auto;
          max-width: 400px;
          object-fit: contain;
          object-position: left center;
          flex: 0 0 19%;
        }
        .profileImg {
          height: 34px;
          width: 34px;
          border-radius: 17px;
        }
        .nameEmail {
          margin-left: 15px;
          flex: 0 0 19%;
          display: flex;
          flex-direction: column;
          line-height: 1;
          text-transform: capitalize;
          .name {
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 4px;
          }
          .email {
            font-size: 13px;
            font-weight: normal;
          }
        }
        .time,
        .balance {
          margin-left: 15px;
          flex: 0 0 20%;
        }
        .balance {
          text-transform: capitalize;
        }

        .btnActions {
          margin-left: auto;
          display: flex;
          .btnAction {
            cursor: pointer;
            cursor: pointer;
            width: 110px;
            height: 35px;
            border: 1px solid $border-color;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 15px;
            &.more {
              width: 135px;
              justify-content: space-between;
              padding: 0 20px;
              position: relative;
              svg {
                color: gray;
              }
              .dropList {
                position: absolute;
                left: -1px;
                right: -1px;
                top: 100%;
                display: flex;
                flex-direction: column;
                border: 1px solid $border-color;
                .dropItm {
                  cursor: pointer;
                  border-top: 1px solid $border-color;
                  background: white;
                  height: 40px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  z-index: 2;
                  &:first-child {
                    border: none;
                  }
                  &:hover {
                    background: whitesmoke;
                  }
                }
              }
            }
            &:hover {
              background: $primary-color;
              color: white;
            }
          }
        }

        &:hover {
          background: whitesmoke;
          filter: drop-shadow(1);
          zoom: 1.03;
        }
        &.true {
          zoom: 1.03;
          opacity: 1;
        }
      }
      .fxClose {
        flex: 1;
      }
      &.true {
        .userItem {
          .nameEmail,
          .time {
            flex: 0 0 32%;
          }
          .hideOpen {
            display: none;
          }
        }
      }
    }
  }
}
