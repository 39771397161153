.deleteWithdraw {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 10;
  display: flex;
  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.65);
    z-index: 0;
  }
  .modalCopy {
    margin: auto;
    z-index: 1;
    min-height: 450px;
    width: 550px;
    background: white;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: relative;
    .head {
      height: 75px;
      background: #464b4e;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        height: 40%;
        filter: brightness(0) invert(1);
      }
      span {
        color: white;
        font-size: 20px;
        font-weight: 600;
      }
    }
    .deleteContent {
      flex: 1;
      padding: 60px;
      line-height: 1;
      .labelDel {
        font-size: 19px;
        font-weight: 600;
        line-height: 1.6;
      }
      .btnDropdown {
        margin-top: 20px;
        height: 55px;
        border: 1px solid $border-color;
        flex: 1;
        padding: 0 25px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        text-transform: capitalize;
        .dropList {
          position: absolute;
          top: 100%;
          left: -1px;
          right: -1px;
          height: 320%;
          background: white;
          z-index: 2;
          border: 1px solid $border-color;
          overflow: auto;
          .dropItem {
            height: 54px;
            border-bottom: 1px solid $border-color;
            display: flex;
            align-items: center;
            padding: 0 25px;
            text-transform: capitalize;
          }
        }
      }
      .notesWrapper {
        margin-top: 30px;
        width: 100%;
        height: 120px;
        position: relative;
        .ipNotes {
          width: 100%;
          height: 100%;
          columns: 2;
          padding: 10px;
          resize: none;
          border: 1px solid $border-color;
          &:focus ~ label,
          &.true ~ label {
            display: none;
          }
        }
        label {
          opacity: 0.65;
          position: absolute;
          text-transform: capitalize;
          left: 26px;
          top: 20px;
        }
        &.sub {
          height: 80px;
        }
      }
      .addKey {
        margin-top: 20px;
        display: flex;
        align-items: center;
        height: 50px;
        svg {
          margin: 0 8px;
          height: 60%;
          width: auto;
        }
        input {
          padding-left: 20px;
          height: 100%;
          border: 1px solid $border-color;
          border-radius: 5px;
          width: 0;
          flex: 1;
        }
      }
      .btnUpdate {
        cursor: pointer;
        margin: 0 auto;
        margin-top: 50px;
        height: 50px;
        background: $primary-color;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 45%;
      }
    }
    .deleteContentRes {
      flex: 1;
      padding: 50px 60px;
      .labelDel {
        font-size: 19px;
        font-weight: 600;
        line-height: 1.6;
        text-align: center;
      }
      .steps {
        margin-top: 25px;
        margin-bottom: 60px;
        display: flex;
        justify-content: space-between;
        .step {
          flex: 0 0 43%;
          display: flex;
          flex-direction: column;
          margin-top: 5px;
          .stepLabel {
            text-align: center;
            font-size: 16px;
          }
          .stepValue {
            margin-top: 12px;
            height: 55px;
            font-size: 19px;
            border: 1px solid $border-color;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
      .transactionValues {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        .transaction {
          margin: 20px 0;
          display: flex;
          justify-content: space-between;
          .label {
            font-weight: 600;
          }
          .value {
            cursor: pointer;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
    .landingLottie {
      position: absolute !important;
    }
    .footer {
      height: 70px;
      display: flex;
      border-top: 1px solid $border-color;
      .btnFoot {
        cursor: pointer;
        flex: 0 0 33.33%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-left: 1px solid $border-color;
        font-weight: 600;
        font-size: 20px;
        opacity: 0.5;
        img {
          width: 60%;
          height: auto;
          max-height: 65px;
          transition: all 400ms ease;
        }
        &:hover {
          img {
            transform: scale(1.1);
          }
        }
        &.true {
          opacity: 1;
        }
      }
    }
  }
}
