.godsIoApps {
  flex: 1;
  display: flex;
  flex-direction: column;
  .appsContent {
    height: 0;
    flex: 1;
    display: flex;
    .appList {
      width: 0;
      flex: 1;
      > div {
        display: flex;
        flex-direction: column;
        .appItem {
          cursor: pointer;
          height: 130px;
          flex: 0 0 130px;
          border-bottom: 1px solid $border-color;
          padding: 0 60px;
          display: flex;
          align-items: center;
          opacity: 0.3;
          .cpyIcn {
            font-size: 0.8em;
            margin-left: 0.5em;
            &:hover {
              transform: scale(1.1);
            }
          }
          .appIcon {
            height: 70px;
            width: 70px;
            object-fit: contain;
            margin-right: 20px;
          }
          .name {
            flex: 0 0 21%;
            .appName {
              font-size: 19px;
              font-weight: 600;
              line-height: 1;
            }
            .appCode {
              font-size: 17px;
            }
          }
          .status {
            padding-left: 6%;
            font-size: 19px;
            font-weight: 600;
            text-transform: capitalize;
            flex: 1;
          }
          .createdBy {
            font-size: 19px;
            font-weight: 600;
          }
          &:hover {
            background: whitesmoke;
          }
          &.true {
            opacity: 1;
          }
        }
        .fxClose {
          flex: 1;
        }
      }
    }
    &.true {
      .appList {
        > div {
          .appItem {
            .name {
              flex: 0 0 45%;
            }
            .status,
            .createdBy {
              display: none;
            }
          }
        }
      }
    }
  }
}
