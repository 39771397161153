@import "../../static/scss/colors";

.editView {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: white;
  display: flex;
  flex-direction: column;
  .header {
    display: flex;
    align-items: center;
    padding: 30px 60px;
    border-bottom: 1px solid $border-color;
    .detail {
      flex: 1;
      display: flex;
      align-items: center;
      .icon {
        height: 60px;
        width: 60px;
        object-fit: contain;
        margin-right: 10px;
      }
      .texts {
        display: flex;
        flex-direction: column;
        .title {
          font-size: 28px;
          font-weight: 600;
        }
        .subTitle {
          display: flex;
          align-items: center;
          font-size: 18px;
          img {
            height: 20px;
            width: 20px;
            margin: 0 6px;
          }
        }
      }
    }
    .backBtn {
      height: 50px;
      width: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: all 400ms ease;
      border: 1px solid $border-color;
      margin-left: 8px;
      img {
        height: 20px;
        width: 20px;
        transition: all 400ms ease;
        filter: brightness(0);
      }
      &:hover {
        img {
          transform: scale(1.2);
        }
      }
    }
  }
  .appContent {
    height: 0;
    flex: 1;
    overflow: auto;
    padding: 60px;
    .inpRow {
      display: flex;
      justify-content: space-between;
      margin-bottom: 50px;
      .inpGrp {
        flex: 1;
        display: flex;
        flex-direction: column;
        .label {
          font-size: 20px;
          font-weight: 600;
          margin-bottom: 15px;
        }
        .inpWrap {
          flex: 0 0 70px;
          border: 1px solid $border-color;
          padding: 0 30px;
          display: flex;
          align-items: center;
          position: relative;
          .input {
            width: 0;
            height: 100%;
            flex: 1;
            border: none;
            background: none;
          }
          .textarea {
            width: 0;
            height: 180px;
            flex: 1;
            border: none;
            background: none;
            padding: 30px 0;
            resize: vertical;
          }
          .inpIcon {
            height: 25px;
            width: 20px;
            cursor: pointer;
            transition: all 400ms ease;
            &:hover {
              transform: scale(1.1);
            }
          }
        }
        .appIconWrap,
        .coverPhotoWrap {
          height: 200px;
          border: 1px solid $border-color;
          img {
            height: 100%;
            width: 100%;
            object-fit: contain;
          }
        }
        .appIconWrap {
          width: 200px;
        }
        .coverPhotoWrap {
          width: 100%;
        }
        &.color {
          .inpWrap {
            padding-left: 50px;
            margin-bottom: 20px;
            .colorBar {
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              width: 20px;
              background: whitesmoke;
              border-right: 1px solid $border-color;
            }
          }
        }
        &.w3 {
          flex: 0 0 32%;
        }
      }
    }
    .cardGroup {
      display: flex;
      flex-direction: column;
      margin-bottom: 50px;
      .label {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 15px;
      }
      .card {
        flex: 1;
        display: flex;
        align-items: center;
        border: 1px solid $border-color;
        padding: 40px;
        margin-bottom: 20px;
        .icon {
          height: 60px;
          width: 60px;
          object-fit: contain;
          margin-right: 10px;
        }
        .texts {
          display: flex;
          flex-direction: column;
          .title {
            font-size: 28px;
            font-weight: 600;
            display: flex;
            align-items: center;
            .inpIcon {
              margin-left: 4px;
              height: 26px;
              width: 26px;
            }
          }
          .subTitle {
            display: flex;
            align-items: center;
            font-size: 18px;
            .inpIcon {
              margin-left: 4px;
              height: 16px;
              width: 16px;
            }
          }
        }
      }
      &.w2 {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        .label {
          flex: 0 0 100%;
        }
        .card {
          flex: 0 0 49%;
        }
      }
      &.w3 {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        .label {
          flex: 0 0 100%;
        }
        .card {
          flex: 0 0 32%;
          padding: 20px 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          .smTitle {
            font-size: 20px;
            text-align: center;
            font-weight: 500;
            opacity: 0.4;
          }
          &.true {
            .smTitle {
              opacity: 1;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}
