.userSidebar {
  display: flex;
  flex-direction: column;
  .userHeads {
    border-bottom: 1px solid $border-color;
    display: flex;
    height: fit-content;
    padding: 25px 20px;
    align-items: center;
    display: flex;
    img {
      width: 55px;
      height: 55px;
      border-radius: 50%;
      margin-right: 10px;
      overflow: hidden;
      border: 1px solid $border-color;
    }
    .detail {
      display: flex;
      flex-direction: column;
      flex: 1;
      .name {
        font-size: 21px;
        font-weight: 600;
        line-height: 1.2;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .email {
        text-transform: capitalize;
        font-size: 16px;
        display: flex;
        align-items: center;
        span {
          cursor: pointer;
          margin: 0 2px;
          &:last-of-type,
          &:hover {
            font-weight: 600;
            text-decoration: underline;
          }
        }
      }
    }
  }
  .formArea {
    height: 0;
    flex: 1;
    text-transform: capitalize;
    > div {
      padding: 20px 25px;
      .group {
        margin: 20px 0;
        .labl {
          font-weight: 500;
          font-size: 20px;
          &.fwn {
            font-weight: normal;
          }
        }
        .box {
          height: 65px;
          margin-top: 10px;
          display: flex;
          justify-content: space-between;
          .debit,
          .credit {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 0 0 49%;
            border: 1px solid $border-color;
            font-size: 20px;
            font-weight: 600;
            svg {
              font-size: 35px;
              margin-right: 10px;
              &.down {
                color: #a64c31;
              }
              &.up {
                color: #66b49d;
              }
            }
            &:hover,
            &.true {
              background: whitesmoke;
            }
          }
        }
        .boxValue {
          height: 65px;
          margin-top: 10px;
          border: 1px solid $border-color;
          display: flex;
          padding: 0 30px;
          img {
            width: 22px;
            height: 22px;
            margin-right: 6px;
          }
          span {
            font-weight: 600;
            font-size: 17px;
            &.select {
              cursor: pointer;
              opacity: 0.5;
            }
          }
          .value {
            margin-left: auto;
          }
          input {
            width: 0;
            flex: 1;
            background: none;
            border: none;
          }
          .coinDetail {
            border-left: 1px solid $border-color;
            padding: 0 20px;
            display: flex;
            align-items: center;
          }
          &.aic {
            align-items: center;
          }
          &.big {
            height: 100px;
            img {
              width: 35px;
              height: 35px;
            }
            span {
              font-size: 20px;
            }
          }
        }
      }
    }
  }
  .godsEyeFilterCurrency {
    text-transform: capitalize;
    .coinList {
      .coinItem {
        .appDetail {
          display: flex;
          flex-direction: column;
          .app {
            font-size: 20px;
            line-height: 1;
          }
          .profile {
            font-size: 13px;
            font-weight: 400;
          }
        }
        .value {
          .native {
            display: flex;
          }
          .usd {
            display: none;
          }
          &:hover {
            .native {
              display: none;
            }
            .usd {
              display: flex;
            }
          }
        }
        &.small {
          height: 55px;
          flex: 0 0 55px;
          &:hover {
            background: none;
          }
        }
        &.colorBlack {
          margin-bottom: 25px;
          color: $primary-color;
          path {
            fill: $primary-color;
          }
        }
        &.colorRed {
          margin-bottom: 25px;
          color: #e73625;
          path {
            fill: #e73625;
          }
        }
        &.disable {
          opacity: 0.4;
        }
      }
    }
  }
  .addNew {
    cursor: pointer;
    border-top: 1px solid $border-color;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    &:hover {
      background: whitesmoke;
    }
  }
  .btCredit {
    text-transform: capitalize;
    background: $primary-color;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 24px;
    font-weight: 600;
  }
}
