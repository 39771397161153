.copyPubModal {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  z-index: 2;
  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.65);
    z-index: 0;
  }
  .modalCopy {
    margin: auto;
    z-index: 1;
    height: 450px;
    width: 550px;
    background: white;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .head {
      height: 75px;
      background: #464b4e;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        height: 40%;
        filter: brightness(0) invert(1);
      }
    }
    .copyContent {
      flex: 1;
      display: flex;
      flex-direction: column;
      padding: 60px;
      line-height: 1;
      overflow: auto;
      position: relative;
      .cpLabel {
        font-size: 22px;
        font-weight: 600;
      }
      .stepLabel {
        width: 100%;
        font-size: 23px;
        font-weight: 600;
      }
      .step {
        margin-top: 4px;
        font-size: 18px;
        font-weight: 500;
      }
      .inputValue {
        margin-top: 30px;
        width: 100%;
        height: 60px;
        border: none;
        border-bottom: 2px solid $border-color;
      }
      .btnNextStep {
        margin: 0 auto;
        font-size: 20px;
        margin-top: 30px;
        padding: 20px 30px;
        border: 1px solid $border-color;
        cursor: pointer;
        font-weight: 600;
        &:hover {
          background: $primary-color;
          color: white;
        }
      }
      .btnDrop {
        margin-top: 20px;
        height: 55px;
        display: flex;
        .btnDropdown {
          border: 1px solid $border-color;
          flex: 1;
          margin-right: 10px;
          padding: 0 25px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          position: relative;
          text-transform: capitalize;
          * {
            z-index: 1;
          }
          .backDrop {
            cursor: pointer;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            z-index: 0;
          }
          input {
            border: none;
            background: none;
            outline: none;
          }
          .dropList {
            position: absolute;
            top: 100%;
            left: -1px;
            right: -1px;
            height: 320%;
            background: white;
            z-index: 2;
            border: 1px solid $border-color;
            overflow: auto;
            .dropItem {
              height: 54px;
              border-bottom: 1px solid $border-color;
              display: flex;
              align-items: center;
              padding: 0 25px;
              text-transform: capitalize;
            }
          }
        }
        .btnShow {
          border: 1px solid $border-color;
          padding: 0 25px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
      }
      .res {
        margin-top: 80px;
        display: flex;
        padding: 0 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid $border-color;
        .resVal {
          flex: 1;
          text-transform: capitalize;
        }
        .icon {
          font-size: 24px;
          cursor: pointer;
        }
      }
    }
  }
}
