:root {
  --primary-color: #484848;
  --secondary-color: #484848;
}
$primary-color: var(--primary-color);
$secondary-color: var(--secondary-color);
$border-color: #e7e7e780;
nav {
  .divider {
    border-left: 2px solid $border-color;
    height: 70%;
    margin: auto 0;
  }
  .betsDashNavbar {
    display: flex;
    border-bottom: $border-color solid 1.5px;
    height: 80px;
    background-color: white;
    h2 {
      padding: 0 50px;
      line-height: 1;
      margin: auto 0;
      margin-left: 20px;
      font-weight: bold;
      font-size: 28px;
    }
    .navList {
      display: flex;
      .navBarWrapper {
        position: relative;
        display: flex;
        opacity: 0.5;
        .navLogoText {
          display: flex;
          padding: 0 50px;
          align-items: center;
          .navLogo {
            font-weight: bold;
            height: 40%;
          }
          span {
            margin-left: 8px;
            font-size: 24px;
            font-weight: 600;
            white-space: nowrap;
          }
        }
        .btnClose {
          position: absolute;
          top: 10px;
          right: 10px;
          height: 19px;
          width: 19px;
          transform: rotate(45deg);
          display: flex;
          &::before,
          &::after {
            content: '';
            position: absolute;
            width: auto;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            background: $primary-color;
          }
          &::before {
            height: 100%;
            width: 3px;
          }
          &::after {
            height: 3px;
            width: 100%;
          }
        }
        &.true {
          opacity: 1;
        }
      }
      .addNav {
        width: 78px;
        height: 100%;
        position: relative;
        .bg {
          height: 100%;
          width: auto;
        }
        &::before,
        &::after {
          content: '';
          position: absolute;
          width: auto;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          background: #e7e7e7;
        }
        &::before {
          height: 35%;
          width: 4px;
        }
        &::after {
          height: 4px;
          width: 35%;
        }
      }
    }
    .dropDown {
      position: relative;
      cursor: pointer;
      min-width: 130px;
      margin: auto 20px auto auto;
      border: 1px solid #d2d7e2;
      color: #32364f;
      height: 40px;
      padding: 0 20px;
      border-radius: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 13px;
      font-weight: 600;
      span {
        margin-right: 15px;
      }
      .dropList {
        left: -1px;
        right: -1px;
        top: 100%;
        position: absolute;
        background: white;
        border: 1px solid #d2d7e2;
        color: #32364f;
        border-radius: 0 0 8px 8px;
        .dropdownContent {
          height: 38px;
          padding: 0 20px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 13px;
          border-top: 1px solid #d2d7e2;
          &:first-child {
            border: none;
          }
        }
      }
      &.true {
        border-radius: 8px 8px 0 0;
      }
    }
    .new-project {
      cursor: pointer;
      margin: auto 20px auto 0;
      background-color: $primary-color;
      height: 40px;
      padding: 0 12px;
      display: flex;
      border-radius: 8px;
      h5 {
        display: flex;
        color: white;
        margin: auto;
        line-height: 1;
        font-size: 13px;
        font-weight: 600;
        svg {
          margin-right: 6px;
        }
      }
    }
    .new-btn {
      margin-left: auto;
      height: 100%;
      position: relative;
      background: $border-color;
      .bg {
        height: 100%;
        width: auto;
      }
      &::before,
      &::after {
        content: '';
        position: absolute;
        width: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        background: #32364f;
      }
      &::before {
        height: 35%;
        width: 4px;
      }
      &::after {
        height: 4px;
        width: 35%;
      }
      &:hover {
        filter: brightness(0.9);
      }
    }
    .publication {
      margin-left: auto;
      width: 240px;
      padding: 0 30px;
      display: flex;
      align-items: center;
      border-left: 1px solid $border-color;
      .logoIcn {
        height: 40%;
        width: auto;
        margin-right: 6px;
      }
      span {
        font-size: 20px;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-weight: 600;
      }
      &.true {
        margin-left: auto;
      }
      &:hover {
        background: whitesmoke;
        * {
          transform: scale(1.05);
        }
      }
    }
  }
  .navBottom {
    display: flex;
    border-bottom: 1px solid $border-color;
    height: 50px;
    .tab {
      width: 0;
      flex: 1;
      height: 100%;
      margin: 0;
      display: flex;
      .tabitm {
        padding: 0;
        cursor: pointer;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        justify-content: center;
        opacity: 0.5;
        font-size: 12px;
        h6 {
          padding: 0 50px;
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          font-size: 14px;
          line-height: 1;
          font-weight: 400;
          margin: 0;
          color: rgba(0, 10, 0, 0.5);
        }
        &::after {
          transition: 300ms ease all;
          margin-top: auto;
          content: '';
          height: 3px;
          border-radius: 2px;
          background-color: $primary-color;
          width: 0;
        }
        &:hover {
          opacity: 0.9;
          &::after {
            width: 100%;
            background-color: gray;
          }
          h6 {
            color: $secondary-color;
            font-size: 14px;
            font-weight: 600;
          }
        }
        &.true {
          opacity: 1;
          h6 {
            color: $secondary-color;
            font-size: 14px;
            font-weight: 600;
          }
          &::after {
            width: 100%;
          }
        }
      }
    }
    .search {
      padding: 0 40px;
      margin-left: auto;
      width: 280px;
      border: none;
      border-left: 1px solid $border-color;
    }
  }
}
