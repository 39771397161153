:root {
  --primary-color: #484848;
  --secondary-color: #484848;
}
$primary-color: var(--primary-color);
$secondary-color: var(--secondary-color);
$border-color: #e7e7e780;
.viewSwitcher {
  padding: 8px 0;
  display: flex;
  .btSwitchView {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    opacity: 0.6;
    color: $primary-color;
    svg {
      height: 60%;
      width: auto;
      margin-right: 6px;
      path {
        fill: $primary-color;
      }
    }
    &:hover {
      opacity: 0.8;
      * {
        transform: scale(1.06);
      }
    }
    &.true {
      opacity: 1;
      font-weight: 600;
    }
    &:first-child {
      border-right: 1px solid $border-color;
    }
  }
}
