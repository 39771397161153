.globalSidebar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #000000d0;
  }
  .sidebar {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 32%;
    background: white;
    display: flex;
    flex-direction: column;
    .head {
      height: 80px;
      display: flex;
      align-items: center;
      position: relative;
      .back {
        cursor: pointer;
        width: 80px;
        max-width: 80%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid #000;
        img {
          width: 100px;
          height: 100%;
          padding: 24px;
        }
      }
      .text {
        margin-left: 40px;
        font-size: 24px;
        font-weight: 600;
      }
      .closeBtn {
        height: 30px;
        width: 30px;
        position: absolute;
        transform: rotate(45deg);
        right: 40px;
        top: 0;
        bottom: 0;
        margin: auto;
        cursor: pointer;
        &::before,
        &::after {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background: $primary-color;
          margin: auto;
        }
        &::before {
          width: 3px;
        }
        &::after {
          height: 3px;
        }
        &:hover {
          &::before,
          &::after {
            filter: drop-shadow(0px 4px 4px rgb(255, 255, 255));
          }
        }
      }
      &.dark {
        background: $primary-color;
        .back {
          background: white;
          &:hover {
            img {
              filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
            }
          }
        }
        .text {
          color: white;
        }
        .closeBtn {
          &::before,
          &::after {
            background: white;
          }
        }
      }
    }
    .searchBox {
      margin: 0 35px;
      height: 60px;
      flex: 0 0 60px;
      border: 1px solid $border-color;
      display: flex;
      align-items: center;
      input {
        width: 0;
        flex: 1;
        border: none;
        background: none;
        outline: none;
        padding: 0 20px;
        &::placeholder {
          text-transform: capitalize;
        }
      }
    }
    .verticalScrollBox {
      margin-top: 15px;
      > div {
        display: flex;
        flex-wrap: wrap;
        overflow-x: hidden !important;
        overflow-y: scroll;
        padding: 20px 35px;
        justify-content: space-between;
        .itemBox {
          flex: 0 0 28%;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-bottom: 20px;
          cursor: pointer;
          transition: all 400ms ease;
          .iconWrap {
            position: relative;
            border: 1px solid $border-color;
            width: 100%;
            height: fit-content;
            svg {
              width: 100%;
              height: auto;
            }
            .icon {
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              padding: 30px;
              height: 100%;
              width: 100%;
              object-fit: contain;
            }
          }
          .label {
            font-size: 13px;
            margin-top: 5px;
            font-weight: 500;
            text-align: center;
          }
          &:hover {
            transform: scale(1.05);
          }
        }
        .inv {
          visibility: hidden;
        }
      }
    }
    .coinList {
      height: 0 !important;
      flex: 1;
      > div {
        display: flex;
        flex-direction: column;
        padding: 40px;
        .coinItem {
          height: 100px;
          flex: 0 0 100px;
          border: 1px solid $border-color;
          display: flex;
          margin-bottom: 20px;
          align-items: center;
          padding: 0 30px;
          color: $primary-color;
          .coin {
            height: 25px;
            width: 25px;
            object-fit: contain;
            margin-right: 10px;
          }
          span {
            font-weight: 600;
            flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            color: $primary-color;
          }
          .value {
            margin-left: auto;
          }
          &:hover {
            background: whitesmoke;
            .nozoom {
              transform: none !important;
              zoom: 1 !important;
            }
          }
          &.disable {
            opacity: 0.4;
          }
        }
        .name {
          font-size: 19px;
          font-weight: 500;
        }
        .inputWrap {
          display: flex;
          height: 60px;
          border: 1px solid $border-color;
          margin: 20px 0 50px 0;
          position: relative;
          flex: 0 0 60px;
          .text {
            width: 0;
            flex: 1;
            border: none;
            padding: 0 30px;
          }
          .fileInp {
            border-left: 1px solid $border-color;
            width: 60px;
            margin: 0;
            img {
              width: 100%;
              height: 100%;
              padding: 15px;
              &:hover {
                filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
              }
            }
            input {
              display: none;
            }
          }
          .domain {
            display: flex;
            align-items: center;
            border-left: 1px solid $border-color;
            width: fit-content;
            padding: 0 40px 0 20px;
          }
          &.options {
            border: none;
            justify-content: space-between;
            .option {
              cursor: pointer;
              flex: 0 0 49%;
              border: 1px solid $border-color;
              display: flex;
              justify-content: center;
              align-items: center;
              &.true {
                box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.75);
              }
            }
          }
        }
      }
    }
    .btnSubmit,
    .newField {
      cursor: pointer;
      margin-top: auto;
      position: sticky;
      bottom: 0;
      left: 0;
      right: 0;
      height: 70px;
      border: none;
      background: $primary-color;
      color: white;
      font-weight: 600;
      font-size: 22px;
      display: flex;
      justify-content: center;
      align-items: center;
      &:disabled {
        opacity: 0.6;
      }
      &:hover {
        text-shadow: 2px 3px 4px rgba(255, 255, 255, 0.589);
      }
    }
    .ftBtns {
      height: 70px;
      border-top: 1px solid $border-color;
      display: flex;
      .newField,
      .btnSubmit {
        flex: 0 0 50%;
        background: white;
        color: $primary-color;
      }
      .newField {
      }
      .btnSubmit {
        border-left: 1px solid $border-color;
        @include btn-animate;
      }
    }
    .titleOp {
      margin: 30px 0;
      font-weight: 600;
      font-size: 21px;
    }
    .searchWrap {
      height: 75px;
      border: 1px solid $border-color;
      display: flex;
      margin: 0;
      input {
        flex: 1;
        border: none;
        background: none;
        padding: 0 30px;
      }
    }
    .searchList {
      height: 0 !important;
      width: unset !important;
      flex: 1;
      border: 1px solid $border-color;
      border-style: none solid;
      margin: 0;
      > div {
        display: flex;
        flex-direction: column;
        .user {
          flex: 0 0 110px;
          max-height: 110px;
          border-bottom: 1px solid $border-color;
          display: flex;
          align-items: center;
          padding: 0 30px;
          cursor: pointer;
          * {
            transition: all 400ms ease;
          }
          img {
            height: 44px;
            width: 44px;
            border-radius: 22px;
          }
          .userDetail {
            margin-left: 15px;
            .name {
              font-size: 20px;
              font-weight: 600;
            }
            .email {
              font-size: 17px;
            }
          }
          &:hover {
            * {
              transform: scale(1.03);
            }
          }
          &.true {
            background: whitesmoke;
          }
        }
      }
    }
  }
}
