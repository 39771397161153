.main-layout {
  display: flex;
  height: 100%;
  width: 100%;
  .site-layout {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  @include xxxl {
    zoom: 0.9;
  }
  @include xxl {
    zoom: 0.8;
  }
  @include xl {
    zoom: 0.7;
  }
  @include lg {
    zoom: 0.6;
  }
}
._14xfZ {
  &.true {
    width: 110px !important;
    ._1OAt- {
      svg {
        height: 30px !important;
        width: 30px !important;
      }
    }
  }
}
