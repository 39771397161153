.homePage {
  flex: 1;
  display: flex;
  flex-direction: column;
  .content {
    height: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .logo {
      height: 38%;
      width: auto;
    }
    .title {
      margin-top: 1%;
      font-size: 8.4vh;
      font-weight: 600;
    }
    .subTitle {
      font-size: 3.4vh;
      font-weight: 300;
    }
    .btns {
      margin-top: 5%;
      width: 45%;
      height: 85px;
      display: flex;
      justify-content: space-between;
      .btnCapital,
      .btnAdmin {
        flex: 0 0 32%;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        font-weight: 500;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        border: 2px solid $border-color;
        text-decoration: none;
        &::before {
          content: "";
          background-color: white;
          width: 100%;
          height: 60px;
          position: absolute;
          left: -100%;
          transform: rotateZ(45deg);
        }
        &:hover {
          &::before {
            transition: all 0.4s ease;
            left: 100%;
          }
        }
      }
      .btnCapital {
        border-color: $primary-color;
        background: $primary-color;
        img {
          height: 60%;
          width: 55%;
        }
      }
      .btnAdmin {
        background: white;
        color: $primary-color;
        img {
          height: 60%;
          width: 55%;
        }
      }
    }
  }
  .poweredBy {
    display: flex;
    flex: 0 0 8%;
    max-height: 8%;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      font-size: 22px;
      margin-right: 10px;
    }
    img {
      height: 30%;
      width: auto;
    }
  }
}
