.newPost {
  flex: 1;
  display: flex;
  flex-direction: column;
  .newPostLander {
    flex: 1;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    .post {
      display: flex;
      flex-direction: column;
      img {
        height: 180px;
      }
      .name {
        margin-top: 40px;
        font-size: 28px;
        text-align: center;
        font-weight: 500;
      }
    }
  }
  .newVideoWrapper {
    position: relative;
    display: flex;
    flex: 1;
    .brainSection {
      flex: 0 0 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .btnBrain {
        border: 2px solid #0052b4;
        padding: 10px 65px;
        img {
          height: 45px;
        }
      }
      .btnLabel {
        margin-top: 15px;
        font-size: 35px;
        font-weight: 600;
        color: #0052b4;
      }
      &.uploaded {
        justify-content: flex-start;
        border-left: 1px solid $border-color;
        .videoCard {
          width: 100%;
          height: 150px;
          display: flex;
          border-bottom: 1px solid $border-color;
          .videoPlaceholder {
            height: 100%;
            width: auto;
          }
          .textContent {
            flex: 1;
            display: flex;
            flex-direction: column;
            padding: 25px;
            padding-bottom: 20px;
            width: 0;
            .title {
              font-size: 24px;
              font-weight: 600;
              color: rgba(0, 10, 0, 0.5);
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            .value {
              font-size: 19px;
              color: rgba(0, 82, 180, 0.5);
            }
            .buttons {
              display: flex;
              margin-top: auto;
              .btnWhite,
              .btnBlue {
                cursor: pointer;
                border: 1px solid $border-color;
                border-radius: 3px;
                font-size: 16px;
                padding: 4px;
                width: 110px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 15px;
                color: #0052b4;
                font-weight: 600;
              }
              .btnBlue {
                cursor: pointer;
                background: #0052b4;
                color: white;
              }
            }
          }
        }
      }
    }
    .postSection {
      flex: 1;
      display: flex;
      margin: 0 auto;
      flex-direction: column;
      align-items: center;
      .topVideoDetails {
        height: 0;
        flex: 0 0 42%;
        width: 100%;
        display: flex;
        .videoHolder {
          width: min-content;
          position: relative;
          height: 100%;
          .imgHolder {
            height: 100%;
            width: auto;
          }
          .videoContent {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            z-index: 1;
            width: 100%;
            height: 100%;
          }
          &.rte {
            flex: 0 0 45%;
            display: flex;
          }
        }
        .videoDetails {
          padding: 20px 60px 20px 60px;
          flex: 1;
          display: flex;
          flex-direction: column;
          .inpTitle {
            font-size: 26px;
            font-weight: 600;
            width: 100%;
            border: none;
            margin-bottom: 6px;
            &::placeholder {
              color: #000a00;
              opacity: 0.5;
            }
          }
          .textArea {
            border: 1px solid $border-color;
            width: 100%;
            height: 0;
            flex: 1;
            resize: none;
            margin-bottom: 10px;
            padding: 25px;
          }
          .uploadThumbnail {
            height: 50px;
            margin: 0;
            display: flex;
            align-items: center;
            .cloudUpload {
              height: 100%;
              width: 70px;
              border: 1px solid $border-color;
              padding: 8px;
              margin-right: 20px;
            }
          }
        }
      }
      .separator {
        width: 100%;
        flex: 0 0 40px;
        border: 1px solid $border-color;
      }
      .categoriesNLists {
        height: 0;
        flex: 1;
        width: 100%;
        display: flex;
        .categoryList {
          border-left: 1px solid $border-color;
          width: 0;
          flex: 0 0 50%;
          display: flex;
          flex-direction: column;
          .head {
            height: 70px;
            border-bottom: 1px solid $border-color;
            border-left: 1px solid $border-color;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 40px;
            .title {
              font-size: 24px;
              font-weight: 600;
            }
            .btnNew {
              cursor: pointer;
              width: 120px;
              padding: 4px;
              border: 1px solid $border-color;
              justify-content: center;
              align-items: center;
              text-align: center;
              font-size: 20px;
              font-weight: 500;
            }
          }
          .catScrlWrap {
            height: 0;
            flex: 1;
            .catItem {
              position: relative;
              height: 110px;
              border-bottom: 1px solid $border-color;
              display: flex;
              padding: 30px;
              align-items: center;
              img {
                height: 60px;
                width: 60px;
                margin-right: 15px;
              }
              .name {
                font-size: 24px;
                font-weight: 600;
              }
              .desc {
                font-size: 15px;
              }
              .btnAction {
                margin-left: auto;
                cursor: pointer;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border: 1px solid $border-color;
                font-size: 20px;
                color: $primary-color;
                padding: 4px 8px;
                width: 140px;
                position: relative;
                .dropList {
                  position: absolute;
                  left: -1px;
                  right: -1px;
                  top: 100%;
                  display: flex;
                  flex-direction: column;
                  border: 1px solid $border-color;
                  .dropItm {
                    cursor: pointer;
                    font-size: 18px;
                    border-top: 1px solid $border-color;
                    background: white;
                    height: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    z-index: 2;
                    &:first-child {
                      border: none;
                    }
                    &:hover {
                      background: whitesmoke;
                    }
                  }
                }
              }
              &.true {
                background: whitesmoke;
              }
            }
          }
        }
      }
      .post {
        position: absolute;
        top: -50px;
        right: 0;
        height: 50px;
        width: 160px;
        font-size: 26px;
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #464b4e;
        color: white;
      }
    }
  }
  .enterVideoId {
    height: 80px;
    border: 1px solid $border-color;
    border-bottom: none;
    color: #0052b4;
    display: flex;
    input {
      padding: 0 50px;
      border: none;
      flex: 1;
      &::placeholder {
        color: #0052b4;
        opacity: 1;
      }
    }
    .btnContinue {
      cursor: pointer;
      height: 100%;
      width: 250px;
      background: #0052b4;
      color: white;
      font-size: 22px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;
    }
  }
}
