.cryptoFilterSidebar {
  .headTabs {
    .tab {
      flex: 0 0 33.33%;
    }
  }
  .godsEyeFilterCurrency {
    .coinList {
      > div {
        padding: 2px;
        .coinItem {
          &.true {
            box-shadow: 0 0 3px #00000050;
          }
          &.disable {
            opacity: 0.4;
          }
        }
      }
    }
  }
}
