.assetIoFed {
  flex: 1;
  display: flex;
  flex-direction: column;
  .wrapperContent {
    flex: 1;
    display: flex;
    color: $primary-color;
    .scrollWrap {
      height: unset !important;
      width: unset !important;
      flex: 1;
      .scrollView {
        .listItem {
          height: 130px;
          border-bottom: 1px solid $border-color;
          padding: 0 70px;
          display: flex;
          align-items: center;
          img {
            height: 30%;
          }
          .name {
            margin-left: 20px;
            font-size: 26px;
            font-weight: 600;
          }
          .percent {
            margin-left: auto;
            font-size: 22px;
          }
          .btnChange {
            height: 24%;
            padding: 0 22px;
            cursor: pointer;
            border: 1px solid $border-color;
            display: flex;
            font-weight: 600;
            align-items: center;
            justify-content: center;
            margin-left: 15px;
          }
          &:hover,
          &.true {
            background: whitesmoke;
          }
        }
      }
    }
    .fedralReserve {
      display: none;
      flex: 0 0 32%;
      border-left: 1px solid $border-color;
      padding: 60px;
      flex-direction: column;
      justify-content: space-between;
      .head {
        line-height: 1;
        .title {
          font-size: 28px;
          font-weight: 600;
          margin-bottom: 0.5rem;
        }
        .subTitle {
          font-size: 18px;
        }
      }
      .group {
        .label {
          display: flex;
          font-size: 18px;
          justify-content: space-between;
          align-items: center;
          .btnSwitch {
            font-size: 14px;
            span {
              cursor: pointer;
              &.true {
                font-weight: bold;
              }
            }
          }
        }
        input {
          width: 100%;
          height: 60px;
          background: none;
          border: 1px solid $border-color;
          font-size: 20px;
          padding: 0 40px;
          text-align: right;
        }
      }
      .btnDeclareRate {
        border: 1px solid $primary-color;
        margin-right: auto;
        padding: 15px 30px;
      }
    }
    .assetFedBondSidebar {
      border-left: 1px solid $border-color;
      flex: 0 0 45%;
      display: flex;
      flex-direction: column;
      .head {
        height: 120px;
        border-bottom: 1px solid $border-color;
        padding: 0 60px;
        display: flex;
        align-items: center;
        .coinImage {
          height: 70px;
          width: 70px;
          margin-right: 10px;
        }
        .texts {
          display: flex;
          flex-direction: column;
          flex: 1;
          .title {
            font-size: 30px;
            line-height: 1.1;
            font-weight: 600;
          }
        }
        .btnStandard {
          cursor: pointer;
          font-size: 18px;
          border: 1px solid $border-color;
          height: 50px;
          padding: 0 20px;
          display: flex;
          align-items: center;
        }
      }
      .tabs {
        height: 45px;
        border-bottom: 1px solid $border-color;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        .tab {
          font-size: 16px;
          &.true {
            font-weight: 600;
          }
        }
      }
      .sidebarContent {
        height: 0;
        flex-grow: 1;
        padding: 30px 50px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        color: $primary-color;
        position: relative;
        .closeBtn {
          position: absolute;
          top: 20px;
          right: 20px;
          font-size: 26px;
        }
        .colTitle {
          .title {
            font-size: 40px;
            font-weight: 600;
          }
          .subTitle {
            font-size: 22px;
          }
        }
        .fullBox {
          border: 1px solid $border-color;
          height: 70px;
          display: flex;
          font-size: 24px;
          margin: 40px 0;
          input {
            width: 0;
            flex: 1;
            border: none;
            background: none;
            outline: none;
            padding: 0 20px;
          }
          .label {
            border-left: 1px solid $border-color;
            flex: 0 0 30%;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              height: 24px;
              width: auto;
              margin-right: 10px;
            }
          }
        }
        .cntRow {
          display: flex;
          height: 70px;
          justify-content: space-between;
          .boxWrap {
            border: 1px solid $border-color;
            flex: 0 0 45%;
            display: flex;
            font-size: 24px;
            .value {
              display: flex;
              align-items: center;
              width: 0;
              flex: 1;
              font-weight: 600;
              font-size: 22px;
              padding: 0 20px;
              border-right: 1px solid $border-color;
              &:last-of-type {
                border-right: none;
              }
              &.true {
                background: whitesmoke;
              }
            }
            input {
              width: 0;
              flex: 1;
              border: none;
              background: none;
              outline: none;
              padding: 0 20px;
            }
            .label {
              border-left: 1px solid $border-color;
              flex: 0 0 35%;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .percent {
              border-left: 1px solid $border-color;
              flex: 0 0 20%;
              display: flex;
              align-items: center;
              justify-content: center;
              font-weight: 600;
            }
            .percentLabel {
              flex: 0 0 30%;
              display: flex;
              align-items: center;
              justify-content: center;
              font-weight: 600;
            }
            &.simulate {
              flex: 0 0 30%;
              .value {
                cursor: pointer;
                font-weight: 500;
                font-size: 18px;
                text-align: center;
                display: flex;
                justify-content: center;
              }
            }
          }
          &.single {
            .boxWrap {
              flex: 0 0 65%;
            }
          }
        }
        .rowLabel {
          display: flex;
          justify-content: space-between;
          margin-top: 30px;
          margin-bottom: 15px;
          .label {
            font-size: 20px;
            flex: 0 0 45%;
            font-weight: 500;
            display: flex;
            justify-content: space-between;
            .edit {
              cursor: pointer;
              font-weight: 300;
              font-size: 17px;
              text-decoration: underline;
            }
          }
          &.single {
            .label {
              flex: 0 0 65%;
            }
          }
        }
        .colGroup {
          display: flex;
          flex-direction: column;
          .rowLabel {
            margin-top: 0;
          }
        }
        &.createBond {
          justify-content: center;
        }
      }
    }
    &.true {
      .scrollWrap {
        .scrollView {
          .listItem {
            .btnChange {
              display: none;
            }
          }
        }
      }
      .fedralReserve {
        display: flex;
      }
    }
  }
}
