.toastWrapper {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #000000a0;
  .toastMessage {
    box-sizing: border-box;
    position: absolute;
    right: 50px;
    top: 50px;
    height: 105px;
    max-height: 105px;
    width: 420px;
    background: white;
    padding: 20px;
    display: flex;
    align-items: center;
    .icon {
      width: 45px;
      height: 45px;
      margin-right: 15px;
    }
    .name {
      flex: 1;
      .textOne {
        font-size: 20px;
        font-weight: 600;
      }
      .textTwo {
        font-size: 17px;
      }
    }
    .closeIcn {
      height: 20px;
      width: 20px;
      margin-right: 10px;
    }
  }
}
