@import "../../static/scss/colors";

.tokenApps {
  width: 0;
  flex: 1;
  display: flex;
  position: relative;
  .appsScrollbar {
    width: 0 !important;
    flex: 1;
    > div {
      display: flex;
      flex-direction: column;
      .header {
        top: 0;
        position: sticky;
        flex: 0 0 30px;
        border-bottom: 1px solid $border-color;
        padding: 0 60px;
        background: #464b4e;
        display: flex;
        align-items: center;
        * {
          transition: all 400ms ease;
        }
        .name,
        .brand,
        .founding,
        .users,
        .totalAssets {
          color: white;
          font-size: 14px;
        }
        .name,
        .brand {
          flex: 0 0 25%;
        }
        .founding,
        .users {
          flex: 0 0 17%;
          text-align: right;
        }
        .totalAssets {
          flex: 0 0 16%;
          text-align: right;
        }
        &.true {
          .name,
          .brand {
            flex: 0 0 50%;
          }
          .founding,
          .users,
          .totalAssets {
            display: none;
          }
        }
      }
      .appItem {
        flex: 0 0 100px;
        padding: 0 60px;
        border-bottom: 1px solid $border-color;
        display: flex;
        align-items: center;
        cursor: pointer;
        background: white;
        transition: all 400ms ease;
        * {
          transition: all 400ms ease;
        }
        .name,
        .brand {
          flex: 0 0 25%;
          display: flex;
          align-items: center;
          .icon {
            height: 40px;
            width: 40px;
            object-fit: contain;
            margin-right: 10px;
          }
          .texts {
            display: flex;
            flex-direction: column;
            .title {
              font-size: 20px;
              font-weight: 600;
            }
            .subTitle {
              font-size: 16px;
            }
          }
        }
        .founding {
          flex: 0 0 17%;
          display: flex;
          .texts {
            flex: 1;
            text-align: right;
            display: flex;
            flex-direction: column;
            .title {
              font-size: 20px;
              font-weight: 600;
            }
            .subTitle {
              font-size: 16px;
            }
          }
        }
        .users {
          flex: 0 0 17%;
          text-align: right;
          .texts {
            flex: 1;
            text-align: right;
            display: flex;
            flex-direction: column;
            .title {
              font-size: 20px;
              font-weight: 600;
            }
          }
        }
        .totalAssets {
          flex: 0 0 16%;
          text-align: right;
          .texts {
            flex: 1;
            text-align: right;
            display: flex;
            flex-direction: column;
            .title {
              font-size: 20px;
              font-weight: 600;
            }
          }
        }
        &:hover,
        &.true {
          background: whitesmoke;
          * {
            transform: scale(1.01);
          }
        }
        &.open {
          .name,
          .brand {
            flex: 0 0 50%;
          }
          .founding,
          .users,
          .totalAssets {
            display: none;
          }
        }
      }
    }
  }
}
