.landingPage {
  background: #464b4e;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  .logoName {
    margin: 0 auto;
    display: flex;
    align-items: center;
    height: 140px;
    font-size: 120px;
    font-weight: 700;
    color: white;
    letter-spacing: 25px;
    .logo {
      filter: brightness(0) invert(1);
      height: 100%;
      margin-right: 40px;
    }
  }
  .btnOnGod {
    text-decoration: none;
    margin: 0 auto;
    height: 60px;
    color: white;
    border: 1px solid white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 6%;
    font-size: 24px;
    cursor: pointer;
    &:hover {
      background: white;
      color: #464b4e;
    }
  }
}
