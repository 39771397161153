.bad-dash-sider {
  background-color: white !important;
  display: flex;
  flex-direction: column;
  width: 300px;
  border-right: 1px solid $border-color;
  .logo {
    cursor: pointer;
    height: 80px;
    display: flex;
    border-bottom: 3px solid $border-color;
    img {
      height: 35%;
      margin: auto 10px;
      margin-left: 10%;
      // filter: brightness(0) invert(1);
    }
    &.true {
      img {
        margin: auto;
      }
    }
  }
  .sider-contents {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 0;
    .group {
      height: 0;
      flex: 1;
      display: flex;
      flex-direction: column;
      padding: 15px 0;
      position: relative;
      h5 {
        margin: 0 25px 10px 25px;
        font-size: 14px;
        color: $primary-color;
        display: flex;
        justify-content: space-between;
        font-weight: 600;
        span {
          color: #babbc4;
        }
      }
      .menuScroll {
        .menuList {
          display: flex;
          flex-direction: column;
          .menu-itm {
            text-decoration: none;
            align-items: center;
            flex: 0 0 25%;
            margin: 0;
            cursor: pointer;
            padding: 0 40px;
            display: flex;
            opacity: 0.55;
            svg,
            img {
              margin: auto 0;
              height: 18px;
              width: 18px;
            }
            svg {
              path {
                fill: $primary-color;
              }
            }
            .circle {
              height: 12px;
              width: 18px;
            }
            h5 {
              margin: auto 0 auto 10px;
              font-size: 13px;
              line-height: 1;
              text-transform: capitalize;
              color: $primary-color;
            }
            &.teamforce {
              h5 {
                color: #f45d48;
              }
              svg {
                path {
                  fill: #f45d48;
                }
              }
            }
            &.counsel {
              h5 {
                color: #334480;
              }
              svg {
                path {
                  fill: #334480;
                }
              }
            }
            &.assetsio {
              h5 {
                color: #464b4e;
              }
              svg {
                path {
                  fill: #464b4e;
                }
              }
            }
            &.active {
              opacity: 1;
            }
            &.sticky {
              background: white;
              bottom: 0;
              position: sticky;
            }
            &:hover {
              opacity: 1;
              background: whitesmoke;
            }
            &.bottom {
              margin-top: auto;
              position: sticky;
              bottom: 0;
            }
          }
        }
      }

      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 25px;
        right: 25px;
        height: 2px;
        background-color: $border-color;
      }
      &:last-child {
        &::before {
          content: none;
        }
      }
    }
    .profile-logout {
      display: flex;
      padding: 12px 0;
      margin: 0 20px;
      .dp {
        height: 40px;
        width: 40px;
        border-radius: 25px;
        border: 1px $border-color solid;
        margin: auto 0;
      }
      .name {
        margin: auto 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-grow: 1;
        h4 {
          margin: 0;
          color: $primary-color;
          line-height: 1;
          font-size: 16px;
        }
        a {
          margin: 0;
          color: $primary-color !important;
          line-height: 1;
          font-size: 11px;
          cursor: pointer;
          font-weight: normal;
          opacity: 0.5;
          &:hover {
            opacity: 0.8;
          }
        }
        .msg {
          position: relative;
          height: 24px;
          width: 24px;
          svg {
            height: 100%;
            width: 100%;
            filter: brightness(0);
            transform: rotate(15deg);
          }
          .count {
            z-index: 1;
            background-color: #32364f;
            color: white;
            line-height: 1;
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: bold;
            top: 0;
            right: 0;
            margin-top: -4px;
            margin-right: -6px;
            text-align: center;
            height: 16px;
            width: 16px;
            border-radius: 8px;
            font-size: 10px;
          }
        }
      }
    }
    &.true {
      h5 {
        display: none;
      }
      .group {
        .menuScroll {
          .menuList {
            .menu-itm {
              padding: 0;
              justify-content: center;
              align-items: center;
              h5 {
                display: none;
              }
              svg {
                margin: auto;
                transform: scale(1.5);
              }
            }
          }
        }
        &::before {
          content: "";
          left: 0;
          right: 0;
        }
      }

      .profile-logout {
        display: flex;
        margin: 0 auto;
        .dp {
          height: 50px;
          width: 50px;
          border-radius: 25px;
        }
        .name {
          display: none;
        }
      }
    }
  }
  &.true {
    width: 80px;
  }
}
