.godsEyeUserDetai {
  width: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  .jsonWrapper {
    height: 0;
    flex: 1;
    display: flex;
    .viewOptions {
      flex: 0 0 40%;
      display: flex;
      flex-direction: column;
      border-right: 1px solid $border-color;
      .viewOptionType {
        border: 1px solid $border-color;
        display: flex;
        align-items: center;
        flex: 0 0 25%;
        img {
          height: 30px;
          width: 30px;
          margin-left: 70px;
          margin-right: 20px;
        }
        span {
          font-size: 20px;
        }
        .balanceTransaction {
          display: none;
          width: 27%;
          margin-left: auto;
          margin-right: 6%;
          .balance,
          .transaction {
            cursor: pointer;
            height: 40px;
            border: 1px solid $border-color;
            display: flex;
            justify-content: center;
            align-items: center;
            &.true {
              background: #e7e7e7;
              font-weight: 600;
            }
          }
          .balance {
            margin-bottom: 10px;
          }
        }
        &.true {
          border: 3px solid $border-color;
          img {
            margin-left: 68px;
          }
          .balanceTransaction {
            display: block;
          }
        }
      }
    }
    .jsonView {
      width: 0;
      flex: 1;
      display: flex;
      flex-direction: column;
      .jsonWrap {
        height: 0;
        flex: 1;
        display: flex;
        flex-direction: column;
        .vaultsList {
          height: 110px !important;
          border-bottom: 1px solid $border-color;
          > div {
            display: flex;
            .appItem {
              flex: 0 0 110px;
              width: 110px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: space-between;
              padding: 15px 0;
              opacity: 0.5;
              cursor: pointer;
              img {
                height: 50px;
                width: 50px;
              }
              .name {
                display: none;
                flex: 1;
                justify-content: center;
                align-items: center;
                text-align: center;
                line-height: 1;
                font-size: 14px;
                font-weight: 600;
              }
              &:hover,
              &.true {
                opacity: 100%;
                .name {
                  display: flex;
                }
              }
            }
          }
        }
        .react-json-view {
          height: 0;
          flex: 1;
          overflow: auto;
        }
        .txnWrapper {
          height: 0 !important;
          flex: 1;
          .txnList {
            display: flex;
            flex-direction: column;
            position: relative;
            .header {
              border-top: 1px solid white;
              height: 35px;
              background: $primary-color;
              font-size: 15px;
              font-weight: 500;
              color: white;
              display: flex;
              align-items: center;
              justify-content: space-evenly;
              top: 0;
              position: sticky;
              .asset,
              .balance,
              .usd {
                text-align: center;
                &.jcs {
                  text-align: left;
                }
              }
              .asset {
                padding: 0 35px;
                flex: 0 0 35%;
              }
              .balance,
              .usd {
                flex: 0 0 20%;
              }
              .balance {
                &.deposit {
                  color: #20c529;
                }
                &.withdraw {
                  color: #ff0800;
                }
              }
            }
            .txnItm {
              border-bottom: 1px solid $border-color;
              flex: 0 0 80px;
              display: flex;
              justify-content: space-evenly;
              .asset,
              .balance,
              .usd {
                display: flex;
                text-align: center;
                align-items: center;
                font-size: 20px;
                font-weight: 500;
              }
              .asset {
                padding: 0 30px;
                flex: 0 0 35%;
                max-width: 35%;
                img {
                  height: 28px;
                  width: 28px;
                  object-fit: contain;
                  margin-right: 8px;
                }
                span {
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }
              .balance,
              .usd {
                flex: 0 0 20%;
                max-width: 20%;
                justify-content: center;
                .btnAcces {
                  height: 30px;
                  width: 70%;
                  border: 1px solid $border-color;
                  font-size: 15px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  cursor: pointer;
                }
                &.jcs {
                  justify-content: start;
                }
              }
            }
          }
        }
        .footer {
          flex: 0 0 70px;
          display: flex;
          .btnBox {
            border: 1px solid $border-color;
            display: flex;
            align-items: center;
            font-size: 13px;
            padding: 0 20px;
            .box {
              margin: 0 6px;
              height: 14px;
              width: 28px;
              border-radius: 7px;
              border: 1px solid $primary-color;
              display: flex;
              align-items: center;
              padding: 0 1px;
              .dot {
                height: 10px;
                width: 10px;
                border-radius: 5px;
                background: $primary-color;
              }
              &.true {
                .dot {
                  margin-left: auto;
                }
              }
            }
          }
          .netWorth {
            flex: 1;
            background: $primary-color;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 20px;
            font-weight: 600;
            color: white;
          }
        }
      }
    }
  }
}
