nav {
  .divider {
    border-left: 2px solid $border-color;
    height: 70%;
    margin: auto 0;
  }
  .bets-dash-navbar {
    display: flex;
    border-bottom: $border-color solid 1.5px;
    height: 80px;
    background-color: white;
    h2 {
      padding: 0 50px;
      line-height: 1;
      margin: auto 0;
      margin-left: 20px;
      font-weight: bold;
      font-size: 28px;
    }

    .dropDown {
      position: relative;
      cursor: pointer;
      min-width: 130px;
      margin: auto 20px auto auto;
      border: 1px solid #d2d7e2;
      color: #32364f;
      height: 40px;
      padding: 0 20px;
      border-radius: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 13px;
      font-weight: 600;
      span {
        margin-right: 15px;
      }
      .dropList {
        left: -1px;
        right: -1px;
        top: 100%;
        position: absolute;
        background: white;
        border: 1px solid #d2d7e2;
        color: #32364f;
        border-radius: 0 0 8px 8px;
        .dropdownContent {
          height: 38px;
          padding: 0 20px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 13px;
          border-top: 1px solid #d2d7e2;
          &:first-child {
            border: none;
          }
        }
      }
      &.true {
        border-radius: 8px 8px 0 0;
      }
    }
    .new-project {
      cursor: pointer;
      margin: auto 20px auto 0;
      background-color: $primary-color;
      height: 40px;
      padding: 0 12px;
      display: flex;
      border-radius: 8px;
      h5 {
        display: flex;
        color: white;
        margin: auto;
        line-height: 1;
        font-size: 13px;
        font-weight: 600;
        svg {
          margin-right: 6px;
        }
      }
    }
    .new-btn {
      margin-left: auto;
      height: 100%;
      position: relative;
      background: $border-color;
      .bg {
        height: 100%;
        width: auto;
      }
      &::before,
      &::after {
        content: "";
        position: absolute;
        width: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        background: #32364f;
      }
      &::before {
        height: 35%;
        width: 4px;
      }
      &::after {
        height: 4px;
        width: 35%;
      }
      &:hover {
        filter: brightness(0.9);
      }
    }
  }
  .nav-bottom {
    display: flex;
    border-bottom: 1px solid $border-color;
    height: 50px;
    .tab {
      width: 0;
      flex: 1;
      height: 100%;
      display: flex;
      .tab-itm {
        flex: 0 0 10%;
        cursor: pointer;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        justify-content: center;
        opacity: 0.5;
        position: relative;
        h6 {
          padding: 0 20px;
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          font-size: 14px;
          line-height: 1;
          font-weight: 600;
          margin: 0;
          color: rgba(0, 10, 0, 0.5);
          white-space: nowrap;
          text-align: center;
        }
        &::after {
          position: absolute;
          transition: 300ms ease all;
          margin-top: auto;
          content: "";
          height: 3px;
          border-radius: 2px;
          background-color: black;
          width: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }
        &:hover {
          &::after {
            width: 100%;
            background-color: gray;
          }
          opacity: 1;
        }
        &.true {
          opacity: 1;
          h6 {
            color: $primary-color;
          }
          &::after {
            width: 100%;
            background-color: black;
          }
        }
      }
      &.true {
        .tab-itm {
          &:last-child {
            margin-left: auto;
            display: flex;
            flex-direction: row;
            justify-content: center;
            padding: 0 10px;
            h6 {
              width: fit-content;
              padding: 0 5px;
              flex: unset;
            }
            img {
              height: 20px;
              width: 20px;
              object-fit: contain;
            }
          }
        }
      }
    }
    .pager {
      display: flex;
      align-items: center;
      img {
        cursor: pointer;
        width: 50px;
        height: 50px;
        padding: 15px;
        border-left: 1px solid $border-color;
        border-right: 1px solid $border-color;
        &.false {
          opacity: 0.5;
          cursor: wait;
        }
      }
      .pageNo {
        min-width: 110px;
        padding: 0 10px;
        text-align: center;
      }
    }
    .viewSwitcher {
      padding: 0;
      display: flex;
      width: 280px;
      .btSwitchView {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 60px;
        border-left: 1px solid $border-color;
        flex: 1;
        img {
          height: 22px;
          width: 22px;
          margin-right: 6px;
        }

        &.false {
          img {
            opacity: 0.4;
          }
        }
        &.true {
          font-weight: 600;
          img {
            transform: scale(1.3);
            opacity: 1;
          }
        }
        &.active {
          font-weight: 600;
          background: $primary-color;
          img {
            transform: scale(1.3);
            opacity: 1;
            filter: brightness(0) invert(1);
          }
        }
        &.searchBtn {
          img {
            transform: scale(1);
            opacity: 1;
          }
        }
        &:first-child {
          border-right: 1px solid $border-color;
        }
      }
    }
    .search {
      padding: 0 40px;
      margin-left: auto;
      width: 280px;
      border: none;
      border-left: 1px solid $border-color;
    }
  }
}

.navList {
  display: flex;
  .navBarWrapper {
    flex: 0 0 220px;
    position: relative;
    display: flex;
    opacity: 0.5;
    .navLogoText {
      flex: 0 0 220px;
      max-width: 220px;
      display: flex;
      padding: 0 30px;
      align-items: center;
      width: fit-content;
      overflow: hidden;
      .navLogo {
        font-weight: bold;
        height: 30%;
        width: auto;
      }
      span {
        width: 0;
        flex: 1;
        margin-left: 8px;
        font-size: 22px;
        font-weight: 600;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    .btnClose {
      position: absolute;
      top: 10px;
      right: 10px;
      height: 19px;
      width: 19px;
      transform: rotate(45deg);
      display: flex;
      &::before,
      &::after {
        content: "";
        position: absolute;
        width: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        background: $primary-color;
      }
      &::before {
        height: 100%;
        width: 3px;
      }
      &::after {
        height: 3px;
        width: 100%;
      }
    }
    &.true {
      opacity: 1;
    }
  }
  .addNav {
    min-width: 78px;
    width: 78px;
    height: 100%;
    position: relative;
    cursor: pointer;
    .bg {
      height: 100%;
      width: auto;
    }
    &::before,
    &::after {
      content: "";
      position: absolute;
      width: auto;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      background: #e7e7e7;
      transition: all 400ms ease;
    }
    &::before {
      height: 35%;
      width: 4px;
    }
    &::after {
      height: 4px;
      width: 35%;
    }
    &:hover {
      &::before,
      &::after {
        transform-origin: center;
        transform: scale(1.1);
        filter: brightness(0.8);
      }
    }
  }
}

.publication {
  margin-left: auto;
  width: 280px;
  padding: 0 30px;
  display: flex;
  align-items: center;
  border-left: 1px solid $border-color;
  .logoIcn {
    height: 40%;
    width: auto;
    margin-right: 6px;
  }
  span {
    font-size: 20px;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 600;
  }
  &.true {
    margin-left: auto;
  }
  &:hover {
    background: $border-color;
    * {
      transform: scale(1.05);
    }
  }
}
