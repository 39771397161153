@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap");

@import "_colors";
@import "mixin";

@import "landingPage";
@import "loginPage";
@import "withdrawalsPage";
@import "engagementPage";
@import "justicePage";
@import "centralBankContent";
@import "printReciptPage";
@import "printReciptAppPage";
@import "recieptDetailPage";
@import "xidPage";
@import "appsPage";
@import "moneyMarkets";
@import "homePage";

@import "mainLayout";
@import "navbar";
@import "sidebar";
@import "landingLottie";

@import "assetIoBalances";
@import "assetIoFed";
@import "viewingAs";
@import "richTextEditor";
@import "newPost";
@import "deleteWithdraw";
@import "bankerTransaction";
@import "godsEyeUsers";
@import "copyModal";
@import "godsEyeDetail";
@import "godsEyeUserFiter";
@import "bankerList";
@import "centralBankSidebar";
@import "globalSidebar";
@import "cryptoFilterSidebar";
@import "godsIoApps";
@import "bankerTxnSidebar";
@import "godsEyeMoreList";
@import "userSidebar";
@import "blockCheckInbox";
@import "blockCheckSidebar";
@import "transactionInspector";
@import "enterPinUnlock";
@import "newConglomerate";
@import "marketsView";
@import "listsFooter";

body {
  font-family: Montserrat !important;
  min-height: 100vh;
  margin: 0;
  display: flex;
  * {
    font-family: Montserrat;
    outline: none;
  }
  // Z Index Toast
  ._2TjJm {
    z-index: 11 !important;
  }
  #root {
    width: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    color: $primary-color;
    ._1V7Hg {
      img {
        max-width: 60%;
      }
      // Logo Size
    }
    .Society {
      max-height: 100vh;
    }
    * {
      box-sizing: border-box;
    }
    .m-auto {
      margin: auto;
    }
    .my-5 {
      margin-top: 3rem;
      margin-bottom: 3rem;
    }
    .ml-auto {
      margin-left: auto;
    }
  }
}
._2TjJm {
  ._3r7Wc {
    ._moAzX {
      overflow: hidden;
      ._309dE,
      ._1whDJ {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
._1xjVr {
  opacity: 1 !important;
}
._1pXXt {
  ._1tVoi {
    &:nth-of-type(1) {
      flex: 0 0 60% !important;
      ._22US8 {
        flex: 0 0 12% !important;
      }
    }
    &:nth-of-type(3) {
      display: none !important;
    }
  }
}
.CommerceLayout {
  ._26zc0 {
    div {
      &:last-of-type {
        margin-left: auto;
      }
    }
  }
}
::-webkit-scrollbar {
  width: 0;
  height: 0;
}
