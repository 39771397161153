.userCtrls {
  height: 80px;
  border-bottom: 1px solid $border-color;
  display: flex;
  .serchInp {
    border: none;
    border: none;
    padding: 0 50px;
    flex: 1;
  }
  .moreFilter {
    width: 210px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1px solid $border-color;
    cursor: pointer;
    &:hover {
      background: whitesmoke;
    }
  }
}
.centralBankContent {
  height: 0;
  flex: 1;
  display: flex;
  .centralCoinsList {
    width: 0;
    flex: 1;
    > div {
      .centralBankCoinItem {
        opacity: 0.3;
        height: 110px;
        border-bottom: 1px solid $border-color;
        background: white;
        padding: 0 70px;
        display: flex;
        align-items: center;
        .coinImg {
          height: 34px;
          width: 34px;
          border-radius: 17px;
        }
        .coinName {
          margin-left: 15px;
          flex: 1;
        }
        .coinVal {
          flex: 0 0 13%;
          color: $primary-color;
          font-weight: 600;
          &.red {
            color: #dc3027;
          }
          &.green {
            color: #27dc45;
          }
        }
        .btnAction {
          cursor: pointer;
          flex: 0 0 10%;
          height: 45px;
          border: 1px solid $border-color;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 500;
          &:hover {
            font-weight: 600;
            background: $primary-color;
            color: white;
          }
        }
        &.true {
          opacity: 1;
        }
        &:hover {
          background: whitesmoke;
          * {
            zoom: 1.1;
          }
        }
      }
    }
  }
}
.converter {
  height: 0;
  flex: 1;
  display: flex;
  .values {
    width: 0;
    flex: 1;
    padding: 6% 100px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    .group {
      width: 80%;
      .label {
        font-size: 19px;
        font-weight: 500;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        .inv {
          cursor: pointer;
          text-decoration: underline;
          &:hover {
            text-shadow: 1px 1px 3px #000000a0;
          }
        }
      }
      .inpWrap {
        height: 80px;
        border: 1px solid $border-color;
        display: flex;
        .inp {
          width: 0;
          flex: 1;
          border: none;
          background: none;
          border-left: 1px solid $border-color;
          padding: 0 40px;
        }
        .imgWrap {
          cursor: pointer;
          width: 80px;
          border-left: 1px solid $border-color;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 35px;
            height: 35px;
          }
          svg {
            color: $primary-color;
            width: 30px;
            height: 30px;
            &:hover {
              zoom: 1.1;
              filter: drop-shadow(2px 4px 4px rgba(0, 0, 0, 0.6));
            }
          }
          &:first-child {
            border-left: none;
          }
        }
      }
    }
  }
  .controlls {
    flex: 0 0 32%;
    display: flex;
    flex-direction: column;
    border-left: 1px solid $border-color;
    .headTabBox {
      display: flex;
      height: 50px;
      border-bottom: 1px solid $border-color;
      .btBack {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 0 0 50px;
        border-left: 1px solid $border-color;
      }
      .tab {
        width: 0;
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
          zoom: 1.1;
          font-weight: 600;
          background: $border-color;
        }
        &.true {
          background: whitesmoke;
          font-weight: 600;
        }
      }
    }
    .contentsIn {
      flex: 1;
      padding: 35px;
      padding-bottom: 0;
      display: flex;
      flex-direction: column;
      .labelItm {
        font-weight: 500;
        color: $primary-color;
        margin-bottom: 10px;
      }
      .searchBox {
        margin: 6px 0;
        height: 50px;
        border: 1px solid $border-color;
        display: flex;
        align-items: center;
        input {
          width: 0;
          flex: 1;
          border: none;
          background: none;
          outline: none;
          padding: 0 20px;
          &::placeholder {
            text-transform: capitalize;
          }
        }
        .icon {
          font-size: 18px;
        }
        .btnOption {
          cursor: pointer;
          flex: 0 0 50%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          &:hover {
            zoom: 1.05;
            font-weight: 600;
          }
          &.true {
            font-weight: 600;
            border-bottom: 1px solid #000;
          }
        }
      }
      .coinList {
        margin-top: 5px;
        height: 0 !important;
        flex: 1;
        > div {
          display: flex;
          flex-direction: column;
          .coinItem {
            height: 90px;
            flex: 0 0 90px;
            border: 1px solid $border-color;
            display: flex;
            margin-bottom: 10px;
            align-items: center;
            padding: 0 30px;
            color: $primary-color;
            .coin {
              height: 30px;
              width: 30px;
              object-fit: contain;
              margin-right: 10px;
            }
            span {
              font-weight: 600;
              flex: 1;
              overflow: hidden;
              text-overflow: ellipsis;
              color: $primary-color;
            }
            .value {
              margin-left: auto;
            }
            &:hover {
              background: whitesmoke;
              * {
                zoom: 1.1;
              }
              .nozoom {
                transform: none !important;
                zoom: 1 !important;
              }
            }
            &.disable {
              opacity: 0.4;
            }
          }
        }
      }
    }
  }
}
