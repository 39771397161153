:root {
  --primary-color: #484848;
  --secondary-color: #484848;
}
$primary-color: var(--primary-color);
$secondary-color: var(--secondary-color);
$border-color: #e7e7e780;
.navBarWrapper {
  position: relative;
  display: flex;
  opacity: 0.5;
  border-right: 1px solid $border-color;
  .navLogoText {
    display: flex;
    padding: 0 50px;
    align-items: center;
    cursor: pointer;
    .navLogo,
    svg {
      height: 40%;
      min-width: 34px;
      width: auto;
      path {
        fill: $secondary-color;
      }
    }
    span {
      margin-left: 8px;
      font-size: 24px;
      font-weight: 600;
      white-space: nowrap;
      color: $secondary-color;
    }
    &:hover {
      transform: scale(1.05);
    }
  }
  .btnClose {
    position: absolute;
    top: 10px;
    right: 10px;
    height: 19px;
    width: 19px;
    transform: rotate(45deg);
    display: flex;
    &::before,
    &::after {
      content: '';
      position: absolute;
      width: auto;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      background: $primary-color;
    }
    &::before {
      height: 100%;
      width: 3px;
    }
    &::after {
      height: 3px;
      width: 100%;
    }
  }
  &.true {
    opacity: 1;
  }
}
