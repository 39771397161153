:root {
  --primary-color: #484848;
  --secondary-color: #484848;
}
$primary-color: var(--primary-color);
$secondary-color: var(--secondary-color);
$border-color: #e7e7e780;

.searchAndFilter {
  height: 80px;
  border-bottom: 1px solid $border-color;
  display: flex;
  position: relative;
  .serchInp {
    border: none;
    border: none;
    outline: none;
    padding: 0 50px;
    flex: 1;
  }
  .moreFilter {
    width: 93.33px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1px solid $border-color;
    cursor: pointer;
    svg {
      width: 30%;
      height: 30%;
      path {
        fill: $primary-color;
      }
    }
    &:hover {
      background: whitesmoke;
    }
  }
  .filterView {
    z-index: 5;
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    border: 1px solid $border-color;
    border-left: none;
    max-height: 60vh;
    background: white;
    overflow: scroll;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    .listItem {
      height: 120px;
      border-bottom: 1px solid $border-color;
      display: flex;
      padding: 0 5%;
      align-items: center;
      .label {
        font-size: 20px;
      }
      .value {
        margin-left: auto;
        font-size: 20px;
      }
      .switchGroup {
        margin-left: auto;
        width: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .switch {
          height: 26px;
          border-radius: 13px;
          width: 50px;
          padding: 0 2px;
          border: 1px solid $primary-color;
          display: flex;
          align-items: center;
          margin-bottom: 2px;
          transition: all 0.4s ease;
          .switchBall {
            transition: all 0.4s ease;
            height: 22px;
            width: 22px;
            border-radius: 11px;
            background: $primary-color;
          }
          &.on {
            .switchBall {
              margin-left: auto;
            }
          }
        }
        .switchLabel {
          font-size: 14px;
          white-space: nowrap;
          text-align: center;
        }
      }
    }
  }
}
