.bankerTransaction {
  flex: 1;
  display: flex;
  flex-direction: column;
  .bankerTransactionDetail {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    .apiProfile {
      cursor: pointer;
      img {
        width: 200px;
        height: auto;
      }
      h5 {
        margin-top: 30px;
        font-size: 30px;
        text-align: center;
        font-weight: 600;
      }
      &:hover {
        background: whitesmoke;
      }
    }
  }
  .jsonWrapper {
    height: 0;
    flex: 1;
    display: flex;
    .viewOptions {
      flex: 0 0 40%;
      display: flex;
      flex-direction: column;
      .viewOptionType {
        border: 1px solid $border-color;
        display: flex;
        align-items: center;
        flex: 0 0 25%;
        img {
          height: 30px;
          width: 30px;
          margin-left: 70px;
          margin-right: 20px;
        }
        span {
          font-size: 20px;
        }
        &.true {
          border: 3px solid $border-color;
          img {
            margin-left: 68px;
          }
        }
      }
    }
    .react-json-view {
      width: 0;
      flex: 1;
      overflow: auto;
    }
  }
}
