@import "../../static/scss/colors";

.bondTiersList {
  width: 0;
  flex: 1;
  display: flex;
  position: relative;
  .bondTiersListCol {
    width: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    .appsScrollbar {
      height: 0 !important;
      flex: 1;
      > div {
        display: flex;
        flex-direction: column;
        .header {
          top: 0;
          position: sticky;
          flex: 0 0 30px;
          border-bottom: 1px solid $border-color;
          padding: 0 60px;
          background: #464b4e;
          display: flex;
          align-items: center;
          * {
            transition: all 400ms ease;
          }
          .name,
          .app,
          .banker,
          .count,
          .type,
          .userCount {
            color: white;
            font-size: 14px;
          }
          .name,
          .app,
          .banker {
            flex: 0 0 20%;
          }
          .type {
            flex: 0 0 16%;
            text-align: right;
          }
          .count,
          .userCount {
            flex: 0 0 12%;
            text-align: right;
          }
          &.true {
            .name {
              flex: 0 0 50%;
            }
            .type {
              flex: 0 0 30%;
            }
            .userCount {
              flex: 0 0 20%;
            }
            .app,
            .banker,
            .count {
              display: none;
            }
          }
        }
        .bondTiersItem {
          flex: 0 0 100px;
          padding: 0 60px;
          border-bottom: 1px solid $border-color;
          display: flex;
          align-items: center;
          cursor: pointer;
          background: white;
          transition: all 400ms ease;
          * {
            transition: all 400ms ease;
          }
          .name,
          .app,
          .banker {
            flex: 0 0 20%;
            display: flex;
            align-items: center;
            .icon {
              height: 40px;
              width: 40px;
              object-fit: contain;
              margin-right: 10px;
            }
            .texts {
              display: flex;
              flex-direction: column;
              .title {
                font-size: 20px;
                font-weight: 600;
              }
              .subTitle {
                font-size: 16px;
              }
            }
          }
          .type {
            flex: 0 0 16%;
            text-align: right;
            .texts {
              flex: 1;
              text-align: right;
              display: flex;
              flex-direction: column;
              .title {
                font-size: 20px;
                font-weight: 600;
                text-transform: capitalize;
              }
            }
          }
          .count,
          .userCount {
            flex: 0 0 12%;
            text-align: right;
            .texts {
              flex: 1;
              text-align: right;
              display: flex;
              flex-direction: column;
              .title {
                font-size: 20px;
                font-weight: 600;
              }
            }
          }
          &:hover,
          &.true {
            background: whitesmoke;
            * {
              transform: scale(1.01);
            }
          }
          &.open {
            .name {
              flex: 0 0 50%;
            }
            .type {
              flex: 0 0 30%;
            }
            .userCount {
              flex: 0 0 20%;
            }
            .app,
            .banker,
            .count {
              display: none;
            }
          }
        }
      }
    }
  }
}
