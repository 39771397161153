.navHeader {
  flex: 0 0 90px;
  height: 90px;
  display: flex;
  border-bottom: 1px solid $border-color;
  align-items: center;
  padding: 0 6%;
  .vaultLogo {
    height: 45%;
    transition: all ease 0.4s;
    &:hover {
      transform: scale(1.05);
    }
  }
  .search {
    height: 60%;
    margin: auto 0;
    margin-left: auto;
    border: 1px solid $border-color;
    flex: 0 0 30%;
    border-radius: 28px;
    padding: 0 40px;
    display: flex;
    align-items: center;
    font-size: 20px;
    .textInput {
      flex: 1;
      font-size: 16px;
      border: none;
      background: none;
      display: flex;
      align-items: center;
    }
  }
  @include md {
    flex: 0 0 80px;
    height: 80px;
    .search {
      display: none;
    }
  }
}
.navCompact {
  flex: 0 0 90px;
  height: 90px;
  display: flex;
  border-bottom: 1px solid $border-color;
  align-items: center;
  .navHome {
    display: flex;
    border-right: 1px solid $border-color;
    &:last-of-type {
      border-right: none;
      border-left: 1px solid $border-color;
    }
    .logo {
      height: 90px;
      flex: 90;
      width: 90px;
      padding: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        transition: 300ms all ease;
      }
      &.sm {
        padding: 24px;
      }
      &:hover {
        img {
          transform: scale(1.1);
        }
      }
    }
  }
  .area {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    .society {
      height: 55px;
      width: auto;
    }
  }
}
.vaultRecieptContent {
  display: flex;
  flex-direction: column;
  flex: 1;
  .carouselWraper {
    padding: 4% 6% 2% 6%;
    .heads {
      .title {
        font-size: 40px;
        font-weight: 700;
      }
      .subTitle {
        font-size: 18px;
        font-weight: 400;
      }
    }
  }
  .scrollList {
    padding: 0 6%;
    display: flex;
    height: 30%;
    overflow: hidden;
    .scrlItm {
      flex: 0 0 26%;
      margin-right: 2%;
      opacity: 0.25;
      &.true {
        opacity: 1;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      &-enter {
        opacity: 0;
        transform: scale(0.9);
        max-width: 0;
      }
      &-enter-active {
        opacity: 1;
        transform: translateX(0);
        transition: all 300ms, transform 300ms;
        max-width: 26%;
      }
      &-exit {
        opacity: 1;
        max-width: 26%;
      }
      &-exit-active {
        max-width: 0;
        opacity: 0;
        transform: scale(0.9);
        transition: all 300ms, transform 300ms;
      }
    }
  }
  .scrollView {
    margin: 5% 0;
    margin-left: 6%;
    height: 0 !important;
    width: unset !important;
    flex: 1;
    > div {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: space-between;
      .app {
        flex: 0 0 46%;
        max-height: 46%;
        width: 350px;
        margin-right: 20px;
        cursor: pointer;
        border: 1px solid $border-color;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 400ms ease;
        height: 65%;
        margin-right: 20px;
        img {
          height: 40%;
          width: 55%;
          object-fit: contain;
          transition: all 400ms ease;
        }
        &:hover {
          background: whitesmoke;
          img {
            transform: scale(1.1);
          }
        }
      }
    }
  }
}
