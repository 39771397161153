:root {
  --primary-color: #484848;
  --secondary-color: #484848;
}
$primary-color: var(--primary-color);
$secondary-color: var(--secondary-color);
$border-color: #e7e7e780;
.badDashSider {
  background-color: white !important;
  display: flex;
  flex-direction: column;
  width: 300px;
  border-right: 1px solid $border-color;
  .logo {
    position: relative;
    cursor: pointer;
    height: 80px;
    display: flex;
    img {
      height: 65%;
      max-width: 80%;
      margin: auto 10px;
      margin-left: 10%;
    }
    &.true {
      img {
        height: 35%;
        margin: auto;
      }
    }
    &::before {
      content: '';
      position: absolute;
      bottom: -1px;
      left: 25px;
      right: 25px;
      height: 2px;
      background-color: $border-color;
    }
  }
  .siderContents {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 0;
    .menuGroupScroll {
      height: 0;
      flex: 1;
      .menuGroupList {
        display: flex;
        flex-direction: column;
        .group {
          height: 0;
          flex: 0 0 33.33%;
          display: flex;
          flex-direction: column;
          padding: 15px 0;
          position: relative;
          h5 {
            margin: 10px 25px;
            font-size: 15px;
            color: $primary-color;
            display: flex;
            justify-content: space-between;
            font-weight: 600;
            cursor: pointer;
            a {
              color: #babbc4;
            }
            .caret {
              cursor: pointer;
              width: 10px;
              height: auto;
              &.down {
                transform: scaleY(-1);
              }
            }
            &:hover {
              * {
                zoom: 1.07;
              }
            }
          }
          .menuScroll {
            .menuList {
              display: flex;
              flex-direction: column;
              .menuItm {
                text-decoration: none;
                align-items: center;
                flex: 0 0 25%;
                margin: 0;
                cursor: pointer;
                padding: 0 25px;
                display: flex;
                opacity: 0.75;
                svg,
                img {
                  margin: auto 0;
                  height: 18px;
                  width: 18px;
                }
                svg {
                  path {
                    fill: $secondary-color;
                  }
                }
                h5 {
                  margin: auto 0 auto 10px;
                  font-size: 14px;
                  line-height: 1;
                  text-transform: capitalize;
                  color: $secondary-color;
                  font-weight: 500;
                }
                &.active {
                  opacity: 1;
                  h5 {
                    color: $secondary-color;
                    font-weight: 600;
                  }
                }
                &.sticky {
                  background: white;
                  bottom: 0;
                  position: sticky;
                }
                &:hover {
                  opacity: 1;
                  background: whitesmoke;
                  * {
                    transform: scale(1.05);
                  }
                }
                &.bottom {
                  margin-top: auto;
                  position: sticky;
                  bottom: 0;
                }
              }
            }
          }

          &::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 25px;
            right: 25px;
            height: 2px;
            background-color: $border-color;
          }
          &.close {
            flex: unset;
            height: fit-content;
            .menuScroll {
              display: none;
            }
          }
        }
      }
    }
    .profileLogout {
      margin-top: auto;
      position: sticky;
      bottom: 0;
      display: flex;
      padding: 12px 20px;
      .dp {
        height: 40px;
        width: 40px;
        border-radius: 25px;
        border: 1px $border-color solid;
        margin: auto 0;
        overflow: hidden;
      }
      .name {
        padding: 0 15px;
        margin: auto 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-grow: 1;
        h4 {
          margin: 0;
          color: $secondary-color;
          line-height: 1;
          font-size: 16px;
        }
        .logout {
          margin: 0;
          color: $secondary-color !important;
          line-height: 1;
          font-size: 11px;
          cursor: pointer;
          font-weight: normal;
          opacity: 0.5;
          &:hover {
            opacity: 0.8;
          }
        }
      }
      .logoutIcon {
        cursor: pointer;
        width: 24px;
        height: 24px;
        margin: auto 0;
        path {
          fill: $primary-color;
        }
        &:hover {
          filter: contrast(2);
          transform: scale(0.8);
        }
      }
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 25px;
        right: 25px;
        height: 2px;
        background-color: $border-color;
      }
    }
    &.true {
      .group {
        h5 {
          display: none !important;
        }
        .menuScroll {
          .menuList {
            .menuItm {
              padding: 0;
              justify-content: center;
              align-items: center;
              h5 {
                display: none;
              }
              svg,
              img {
                margin: auto;
                transform: scale(1.3);
              }
            }
          }
        }
        &::before {
          content: '';
          left: 0;
          right: 0;
        }
      }

      .profileLogout {
        display: flex;
        padding: 12px 0;
        margin: 0 auto;
        .dp {
          height: 50px;
          width: 50px;
          border-radius: 25px;
        }
        .name,
        .logoutIcon {
          display: none;
        }
      }
    }
  }
  &.true {
    width: 80px;
  }
}
