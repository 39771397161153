.blockCheckSidebar {
  max-width: 500px;
  .mailList {
    height: 0 !important;
    flex: 1;
    > div {
      padding: 20px;
      .mailItem {
        height: 110px;
        border: 1px solid $border-color;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        &.true {
          opacity: 0.8;
        }
        .profileImg {
          height: 70px;
          width: 70px;
          margin: 0 20px;
          border-radius: 50%;
        }
        .texts {
          width: 0;
          flex: 1;
          padding-right: 10px;
          .from {
            font-size: 22px;
            font-weight: 500;
            line-height: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .subject {
            font-size: 18px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .time {
            font-size: 12px;
          }
        }
      }
    }
  }
  .openMailView {
    height: 0 !important;
    flex: 1;
    display: flex;
    flex-direction: column;
    .head {
      flex: 0 0 70px;
      border-bottom: 1px solid $border-color;
      display: flex;
      align-items: center;
      padding: 0 40px;
      .icon {
        font-size: 30px;
        margin-right: 30px;
        &:last-child {
          margin-right: 0;
        }
      }
      .del {
        margin-left: auto;
      }
    }
    .content {
      height: 0;
      flex: 1;
      > div {
        padding: 20px;
        overflow-x: unset !important;
        overflow-y: scroll !important;
        .subject {
          font-size: 32px;
        }
        .body {
          font-size: 19px;
        }
      }
    }
  }
}
